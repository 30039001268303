import * as React from "react";
const SvgFacilities = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#facilities-a)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M17.263 13.429h6.015V22h-6.015v-8.571Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 19.068,
  y: 15.265,
  width: 2.406,
  height: 1,
  rx: 0.5,
  fill: "#748094"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.842 10h8.421v12h-8.42V10Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 11.368,
  y: 12.571,
  width: 3.368,
  height: 1,
  rx: 0.5,
  fill: "#748094"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8 22h16",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "facilities-a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(6 6)",
  d: "M0 0h20v20H0z"
}))));
export default SvgFacilities;
