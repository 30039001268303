import * as React from "react";
const SvgInventory = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3304)",
  stroke: "#current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M17.188 13.695v-7.39a.632.632 0 0 0-.32-.547l-6.563-3.711a.61.61 0 0 0-.61 0l-6.562 3.71a.633.633 0 0 0-.32.548v7.39a.633.633 0 0 0 .32.547l6.562 3.711a.61.61 0 0 0 .61 0l6.562-3.71a.633.633 0 0 0 .32-.548v0Z"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10 12.813a2.813 2.813 0 1 0 0-5.626 2.813 2.813 0 0 0 0 5.625Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3304"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgInventory;
