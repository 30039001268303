import { atom } from "recoil";

export const DEFAULT_VIEW = "TimelineMonth";

export const calendarViewAtom = atom({
  key: "calendarView",
  default: {
    currentView: DEFAULT_VIEW,
  },
});
