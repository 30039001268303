import * as React from "react";
const SvgLogout = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#sidebar-logout-a)",
  stroke: "#748094",
  strokeWidth: 1.3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M19.594 12.719 22.875 16l-3.281 3.281M14.125 16h8.75M14.125 22.875H9.75a.625.625 0 0 1-.625-.625V9.75a.625.625 0 0 1 .625-.625h4.375"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "sidebar-logout-a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(6 6)",
  d: "M0 0h20v20H0z"
}))));
export default SvgLogout;
