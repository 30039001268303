const recursiveRef = (enums, prev) =>
  Object.entries(enums).reduce((arr, [key, value]) => {
    if (
      !Array.isArray(value) &&
      typeof value === "object" &&
      Object.keys(value).length
    ) {
      return [
        ...arr,
        ...Object.entries(value).reduce((a, [k, v]) => {
          return [
            ...a,
            ...recursiveRef(v, prev ? `${prev}.${key}.${k}` : `${key}.${k}`),
          ];
        }, []),
      ];
    }
    return [...arr, prev ? `${prev}.${key}` : key];
  }, []);

export default (enums, type) => {
  if (type) {
    switch (type) {
      case "keys":
        return Object.keys(enums);
      case "values":
        return Object.values(enums);
      case "entries":
        return Object.entries(enums);
      case "graphql":
        return Object.keys(enums).join(" ");
      case "mongo":
        return Object.keys(enums);
      case "reference": // ENUM.KEY = KEY => { KEY: KEY }
        return recursiveRef(enums).reduce((obj, item) => {
          const parts = item.split(".");
          return {
            ...obj,
            ...parts.reduce(
              (o, _, idx) => ({
                ...o,
                [parts.slice(0, idx + 1).join(".")]: parts
                  .slice(0, idx + 1)
                  .join("."),
              }),
              {}
            ),
          };
        }, {});
      default:
        break;
    }
  }
  return {
    keys: Object.keys(enums),
    values: Object.keys(enums),
    entries: Object.entries(enums),
    graphql: Object.keys(enums).join(" "),
    mongo: Object.keys(enums),
    reference: Object.keys(enums).reduce(
      (obj, key) => ({ ...obj, [key]: key }),
      {}
    ),
  };
};
