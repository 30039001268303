import { useRecoilState, useSetRecoilState } from "recoil";
import {
  allMembershipChannelsAtom,
  unreadChannelMessagesAtom,
} from "../atoms/_chat";
import { maxWaitTimePromise } from "../utils";

/**
 * Pub nub hook.
 *
 * @param {Object} instance Chat instance from pub nub.
 */
const usePubnubInstance = (instance) => {
  const [unreadChannelMessages, setUnreadChannelMessages] = useRecoilState(
    unreadChannelMessagesAtom,
  );

  const setAllMembershipChannelsCoil = useSetRecoilState(
    allMembershipChannelsAtom,
  );

  if (!instance) return;

  const getMessagesCounts = async (chans, timeTokens) => {
    const res = await Promise.race([
      maxWaitTimePromise(),
      instance.messageCounts({
        channels: chans,
        channelTimetokens: timeTokens,
      }),
    ]);

    if (res.isTimeOut) return { channels: {} };

    return res;
  };

  const getChannelsUnreadMessagesCount = async (
    channels,
    channelTimetokens,
  ) => {
    let res = {};
    const slicedChannels = [];
    while (channels?.length > 0) {
      let currentLength = channels.length;
      if (currentLength > 100) currentLength = 100;
      slicedChannels.push({
        chans: channels.splice(0, currentLength),
        timeTokens: channelTimetokens.splice(0, currentLength),
      });
    }
    for (let chanSliced of slicedChannels) {
      const channelChunks = await getMessagesCounts(
        chanSliced.chans,
        chanSliced.timeTokens,
      );
      res = {
        ...res,
        ...channelChunks.channels,
      };
    }

    setUnreadChannelMessages({
      channels: res,
    });
  };

  const updateSingleMembership = async (channel, updateAfterSend = false) => {
    if (unreadChannelMessages?.channels?.[channel?.id] || updateAfterSend) {
      await instance.objects.setMemberships({
        channels: [channel],
        limit: 1,
        include: {
          customFields: true,
          totalCount: true,
        },
      });
    }
  };

  const getChannelMemberships = async (next) => {
    const res = await Promise.race([
      maxWaitTimePromise(),
      instance.objects.getMemberships({
        page: {
          next,
        },
        include: {
          customFields: true,
          customChannelFields: true,
          totalCount: true,
        },
      }),
    ]);

    return res;
  };

  const getAllChannelMemberships = async () => {
    let getResChannels = [];
    let getRes = await getChannelMemberships();
    if (getRes?.data) {
      getResChannels = [...getResChannels, ...getRes.data];
      while (getRes?.totalCount > getResChannels?.length) {
        getRes = await getChannelMemberships(getRes?.next);
        getResChannels = [...getResChannels, ...getRes.data];
      }
    }

    return getResChannels;
  };

  // method to get message count for all channels
  const getAllUnreadMessageCount = async () => {
    const allChannels = await getAllChannelMemberships();

    const notificationChans = [];
    const timetokens = [];
    const allChannelsCoil = {};

    allChannels.map((channel) => {
      const id = channel.channel.id;
      const lastReadTimeToken = channel.custom?.lastReadTimetoken;

      allChannelsCoil[id] = channel.channel;
      notificationChans.push(id);

      if (lastReadTimeToken) {
        timetokens.push((lastReadTimeToken / 10000 + 1) * 10000);
      } else {
        timetokens.push(new Date().getTime() * 10000);
      }
    });

    setAllMembershipChannelsCoil(allChannelsCoil);
    getChannelsUnreadMessagesCount(notificationChans, timetokens);
  };

  return () => {
    return {
      updateSingleMembership, // calling from outside
      getAllUnreadMessageCount,
    };
  };
};

export default usePubnubInstance;
