import React from "react";
import { useIntl } from "react-intl";
import { Redirect, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "~/components/_toast";
import { ForgotPasswordSuccessForm as Form } from "~/components/form";
import { forgotPassword } from "~/services";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { useGetOemById } from "../services/auth";

const ForgotPasswordSuccess = () => {
  const { messages } = useIntl();
  const { modifier } = useParams();
  const { mutation } = forgotPassword();

  const { error } = useGetOemById(modifier);

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  var renderPage = () => {
    return (
      <div className="auth">
        <div className="auth-main auth-main--full">
          <div className="o-column">
            <div className="auth-box">
              <div className="form-authHead form-authHead--success form-authHead--center">
                <Headline size={HEADLINE_SIZES.SMALL} className="mb-sm">
                  {messages?.login?.forgotPassword.resetSuccess.title}
                </Headline>
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="opacity-70">
                  {messages?.login?.forgotPassword.resetSuccess.message}
                </BodyText>
              </div>

              <Form
                callback={(values) => {
                  mutation(values)
                    .then(() => {
                      toast(
                        <Toast
                          message={
                            messages?.login?.forgotPassword.resetSuccess?.resend
                              ?.resendEmailSuccess
                          }
                        />,
                        {
                          closeButton: false,
                        },
                      );
                    })
                    .catch((e) => {
                      toast(
                        <Toast
                          message={
                            e?.message || `${messages?.login?.wrongCreds}`
                          }
                          type="error"
                        />,
                        { closeButton: false },
                      );
                    });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderPage();
};

export default ForgotPasswordSuccess;
