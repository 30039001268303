import * as React from "react";
const SvgHtmlDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#EE0064"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.86 20.32a.36.36 0 0 1-.36-.36v-3.6a.36.36 0 0 1 .72 0v1.458h2.046v-1.455a.363.363 0 1 1 .726 0v3.594a.363.363 0 1 1-.726 0v-1.461H9.22v1.464a.36.36 0 0 1-.36.36ZM14.111 20.32a.363.363 0 0 1-.363-.363v-3.279H12.69a.339.339 0 0 1 0-.678h2.844a.339.339 0 0 1 0 .678h-1.059v3.279c0 .2-.162.363-.363.363ZM16.574 20.32a.339.339 0 0 1-.34-.339v-3.582a.4.4 0 0 1 .758-.175l1.39 2.836 1.385-2.836a.399.399 0 0 1 .758.175v3.582a.339.339 0 0 1-.678 0v-2.433l-1.32 2.772h-.288l-1.326-2.772v2.433a.339.339 0 0 1-.34.339ZM21.307 20.32v-3.96a.36.36 0 0 1 .72 0v3.282h1.57a.339.339 0 0 1 0 .678h-2.29Z",
  fill: "#fff"
}));
export default SvgHtmlDocument;
