import * as React from "react";
const SvgXlsDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "green"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("g", {
  filter: "url(#a)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "m13.096 19-1.182-1.89H11.8l-1.632-2.346h1.122l1.164 1.776h.114L14.2 19h-1.104Zm-3.006 0 1.566-2.268.726.408L11.164 19H10.09Zm2.514-1.998-.726-.408 1.176-1.83h1.074l-1.524 2.238ZM14.615 19v-4.236h.942V19h-.942Zm.726 0v-.828h2.094V19h-2.094Zm3.716.066c-.348 0-.648-.056-.9-.168a2.138 2.138 0 0 1-.684-.522l.612-.606c.136.156.286.278.45.366.168.084.362.126.582.126.2 0 .354-.036.462-.108a.338.338 0 0 0 .168-.3.381.381 0 0 0-.108-.282.919.919 0 0 0-.288-.186 4.216 4.216 0 0 0-.39-.15 6.035 6.035 0 0 1-.42-.162 2.017 2.017 0 0 1-.39-.234 1.116 1.116 0 0 1-.282-.354 1.202 1.202 0 0 1-.108-.54c0-.26.062-.484.186-.672.128-.188.304-.33.528-.426.224-.1.482-.15.774-.15.296 0 .566.052.81.156.248.104.452.246.612.426l-.612.606a1.408 1.408 0 0 0-.39-.294.966.966 0 0 0-.438-.096.695.695 0 0 0-.39.096.292.292 0 0 0-.144.264c0 .108.036.196.108.264.072.064.166.12.282.168.12.048.25.096.39.144.144.048.286.104.426.168.14.064.268.146.384.246.12.096.216.22.288.372.072.148.108.332.108.552 0 .404-.144.722-.432.954-.288.228-.686.342-1.194.342Z",
  fill: "#fff"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", {
  id: "a",
  x: 6.09,
  y: 14.698,
  width: 18.593,
  height: 12.368,
  filterUnits: "userSpaceOnUse",
  colorInterpolationFilters: "sRGB"
}, /* @__PURE__ */ React.createElement("feFlood", {
  floodOpacity: 0,
  result: "BackgroundImageFix"
}), /* @__PURE__ */ React.createElement("feColorMatrix", {
  in: "SourceAlpha",
  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
  result: "hardAlpha"
}), /* @__PURE__ */ React.createElement("feOffset", {
  dy: 4
}), /* @__PURE__ */ React.createElement("feGaussianBlur", {
  stdDeviation: 2
}), /* @__PURE__ */ React.createElement("feComposite", {
  in2: "hardAlpha",
  operator: "out"
}), /* @__PURE__ */ React.createElement("feColorMatrix", {
  values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
}), /* @__PURE__ */ React.createElement("feBlend", {
  in2: "BackgroundImageFix",
  result: "effect1_dropShadow_10_52"
}), /* @__PURE__ */ React.createElement("feBlend", {
  in: "SourceGraphic",
  in2: "effect1_dropShadow_10_52",
  result: "shape"
}))));
export default SvgXlsDocument;
