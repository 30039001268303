import * as React from "react";
const SvgEmailFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M3.863 4.204h12.273c.753 0 1.364.611 1.364 1.364v8.864c0 .753-.61 1.363-1.363 1.363H3.863c-.752 0-1.363-.61-1.363-1.363V5.568c0-.753.61-1.364 1.363-1.364Z",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m2.6 5.062 6.41 4.586c.58.415 1.358.415 1.938.002l6.449-4.6",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgEmailFilled;
