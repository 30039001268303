import * as React from "react";
const SvgAssetsManagementActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 6a6 6 0 0 1 6-6h20a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z",
  fill: "#fff",
  fillOpacity: 0.1
}), /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#assets-management-active-a)"
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M8.778 14.036a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.778 14.036a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M23.222 14.036a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M23.222 14.036a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "m11.556 19.334 1.11 1.111-1.11-1.11Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m11.556 19.334 1.11 1.111",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "m19.333 11.556 1.111 1.11-1.11-1.11Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m19.333 11.556 1.111 1.11",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M16 21.259a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16 21.259a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M16 6.814a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16 6.814a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "m19.333 20.444 1.111-1.11-1.11 1.11Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m19.333 20.444 1.111-1.11",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "m11.556 12.666 1.11-1.111-1.11 1.11Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m11.556 12.666 1.11-1.111",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "assets-management-active-a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(6 6)",
  d: "M0 0h20v20H0z"
}))));
export default SvgAssetsManagementActive;
