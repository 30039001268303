import * as React from "react";
const SvgPbDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#6974FB"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.36 21.32a.36.36 0 0 1-.36-.36V17.5a.5.5 0 0 1 .5-.5h1.288c.084 0 .228.006.342.024.75.114 1.104.666 1.104 1.38 0 .714-.36 1.266-1.104 1.386a2.524 2.524 0 0 1-.342.024H13.72v1.146a.36.36 0 0 1-.36.36Zm.36-2.184h1.038c.078 0 .186-.006.27-.03.36-.09.474-.426.474-.702 0-.276-.114-.606-.474-.696a1.065 1.065 0 0 0-.27-.03H13.72v1.458ZM19.262 19.046c.42.174.636.552.636.99 0 .768-.444 1.284-1.296 1.284h-1.39a.5.5 0 0 1-.5-.5V17.5a.5.5 0 0 1 .5-.5h1.216c.87 0 1.272.606 1.272 1.188 0 .396-.168.696-.438.858Zm-1.818-1.368v1.044h.972c.312 0 .546-.174.546-.522 0-.3-.204-.522-.546-.522h-.972Zm1.068 2.958c.378 0 .654-.24.654-.624 0-.348-.234-.612-.636-.612h-1.086v1.236h1.068Z",
  fill: "#fff"
}));
export default SvgPbDocument;
