import * as React from "react";
const SvgProductionLines = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_356_4820)",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M9.718 4.323 8.026 6.005a.467.467 0 0 0 0 .663l1.691 1.68a.473.473 0 0 0 .667 0l1.692-1.682a.467.467 0 0 0 0-.663l-1.691-1.68a.473.473 0 0 0-.667 0ZM13.49 8.073l-1.692 1.682a.467.467 0 0 0 0 .663l1.691 1.68a.473.473 0 0 0 .667 0l1.692-1.682a.467.467 0 0 0 0-.663l-1.691-1.68a.473.473 0 0 0-.667 0ZM5.947 8.067 4.255 9.749a.467.467 0 0 0 0 .663l1.69 1.68a.474.474 0 0 0 .667 0l1.692-1.681a.467.467 0 0 0 0-.663l-1.69-1.681a.473.473 0 0 0-.667 0ZM9.718 11.817l-1.692 1.682a.467.467 0 0 0 0 .663l1.691 1.68a.473.473 0 0 0 .667 0l1.692-1.681a.467.467 0 0 0 0-.663l-1.691-1.681a.473.473 0 0 0-.667 0Z"
})), /* @__PURE__ */ React.createElement("rect", {
  x: 0.849,
  y: 10,
  width: 12.942,
  height: 12.942,
  rx: 1.9,
  transform: "rotate(-45 .849 10)",
  stroke: "#748094",
  strokeWidth: 1.2
}), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_356_4820"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(2.51 2.583)",
  d: "M0 0h15.087v15H0z"
}))));
export default SvgProductionLines;
