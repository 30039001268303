import DocDocument from "@shared/svg/doc-document.svg?react";
import EmlDocument from "@shared/svg/eml-document.svg?react";
import EpubDocument from "@shared/svg/epub-document.svg?react";
import HtmlDocument from "@shared/svg/html-document.svg?react";
import ImageDocument from "@shared/svg/image-document.svg?react";
import JsonDocument from "@shared/svg/json-document.svg?react";
import LxmlDocument from "@shared/svg/lxml-document.svg?react";
import MdDocument from "@shared/svg/md-document.svg?react";
import OdtDocument from "@shared/svg/odt-document.svg?react";
import PbDocument from "@shared/svg/pb-document.svg?react";
import PdfDocument from "@shared/svg/pdf-document.svg?react";
import PptDocument from "@shared/svg/ppt-document.svg?react";
import RtfDocument from "@shared/svg/rtf-document.svg?react";
import TxtDocument from "@shared/svg/txt-document.svg?react";
import XlsDocument from "@shared/svg/xls-document.svg?react";

export const EMAIL_FOLDER_KEY = "EmailFolderId";

export const EMAIL_DATE_MONTH_YEAR_FORMAT = "DD/MM/YYYY";
export const EMAIL_LONG_DATE_WITH_TIME_FORMAT = "DD/MM/YYYY, hh:mm A";

export const EMAIL_ATTACHMENT_DOWNLOADING_TOAST_ID =
  "email-attachment-downloading-toast";

export const EMAIL_ATTACHMENT_DOWNLOAD_IDENTIFIER = "downloadEmailFile";

export const EMAIL_FOLDER_TYPES = {
  INBOX: "Inbox",
  DRAFT: "Drafts",
  SENT: "Sent Items",
  OUTBOX: "Outbox",
  TRASH: "Deleted Items",
  SPAM: "Junk Email",
};

export const EMAIL_NOT_CONFIGURED_VARIANT = {
  REGULAR: "regular",
  LARGE: "large",
  SMALL: "small",
};

export const EMAIL_PARTICIPANTS_INPUT_FIELDS = {
  TO: "TO",
  CC: "CC",
  BCC: "BCC",
};

export const EMAIL_ATTACHMENT_LIMIT_MB = 20;

export const MODULES = {
  EMAIL: "EMAIL",
  WORK_ORDER: "WORK_ORDER",
};

export const EMAIL_FOLDER_SORT = {
  [EMAIL_FOLDER_TYPES.INBOX]: 0,
  [EMAIL_FOLDER_TYPES.DRAFT]: 1,
  [EMAIL_FOLDER_TYPES.SENT]: 2,
  [EMAIL_FOLDER_TYPES.OUTBOX]: 3,
  [EMAIL_FOLDER_TYPES.TRASH]: 4,
  [EMAIL_FOLDER_TYPES.SPAM]: 5,
};

export const SUPPORTED_EMAIL_FILE_EXTENSIONS = {
  md: MdDocument,
  pdf: PdfDocument,
  odt: OdtDocument,
  doc: DocDocument,
  docx: DocDocument,
  ppt: PptDocument,
  pptx: PptDocument,
  txt: TxtDocument,
  html: HtmlDocument,
  lxml: LxmlDocument,
  rtf: RtfDocument,
  epub: EpubDocument,
  pb: PbDocument,
  pbtxt: PbDocument,
  json: JsonDocument,
  eml: EmlDocument,
  png: ImageDocument,
  jpg: ImageDocument,
  jpeg: ImageDocument,
  csv: XlsDocument,
  xls: XlsDocument,
};
