import { useMutation } from "@apollo/client";
import client from "../../apollo/_client";
import {
  ASSIGN_OWN_OEM_INVENTORY_PARTS_TO_MACHINE_TEMPLATE_MUTATION,
  GET_OWN_OEM_MACHINE_TEMPLATE_BY_ID,
  MachineTemplate,
  REMOVE_OWN_OEM_INVENTORY_PART_FROM_MACHINE_TEMPLATE_MUTATION,
} from "~/api";

export const updateMachineTemplateCache = (payload) => {
  const machineTemplate = client.readQuery({
    query: GET_OWN_OEM_MACHINE_TEMPLATE_BY_ID,
    variables: { templateId: payload._id },
  });

  if (!machineTemplate) return;

  const { getOwnOemMachineTemplate: cachedMachineTemplate } = machineTemplate;

  if (cachedMachineTemplate) {
    const cloned = { ...cachedMachineTemplate };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_OEM_MACHINE_TEMPLATE_BY_ID,
      variables: { templateId: payload._id },
      data: {
        getOwnOemMachineTemplate: cloned,
      },
    });
  }
};

export const useAssignOwnOemInventoryPartsToMachineTemplate = () => {
  const [add, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_INVENTORY_PARTS_TO_MACHINE_TEMPLATE_MUTATION,
  );

  return {
    addNewParts: async (input) =>
      add({
        variables: { input },
        skip: !input?.machineTemplateId,
        update(
          cache,
          { data: { assignOwnOemInventoryPartsToMachineTemplate: ref } },
        ) {
          cache.modify({
            id: cache.identify({ __typename: "MachineTemplate", id: ref._id }),
            fields: {
              getOwnOemMachineTemplate(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: MachineTemplate.fragments.MachineTemplateFullData,
                  fragmentName: "MachineTemplateFullData",
                });
                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    loading,
    error,
  };
};

export const useRemoveOwnOemInventoryPartFromMachineTemplate = () => {
  const [remove, { loading, error }] = useMutation(
    REMOVE_OWN_OEM_INVENTORY_PART_FROM_MACHINE_TEMPLATE_MUTATION,
  );

  return {
    removePart: async (input) =>
      remove({
        variables: { input },
        skip: !input?.machineTemplateId || !input?.partId,
        update(
          cache,
          { data: { removeOwnOemInventoryPartFromMachineTemplate: ref } },
        ) {
          cache.modify({
            id: cache.identify({ __typename: "MachineTemplate", id: ref._id }),
            fields: {
              getOwnOemMachineTemplate(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: MachineTemplate.fragments.MachineTemplateFullData,
                  fragmentName: "MachineTemplateFullData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    loading,
    error,
  };
};
