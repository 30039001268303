import * as React from "react";
const SvgPptDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#FF7A00"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.252 16h-1.037c-.294 0-.457.194-.457.488v3.516c0 .314.187.488.44.488.251 0 .439-.174.439-.488v-1.027h.65c.802 0 1.465-.568 1.465-1.482 0-.895-.64-1.495-1.5-1.495Zm-.017 2.152h-.598v-1.326h.598c.369 0 .603.288.603.663 0 .376-.234.663-.603.663ZM15.994 16h-1.037c-.293 0-.457.194-.457.488v3.516c0 .314.188.488.44.488.251 0 .439-.174.439-.488v-1.027h.65c.803 0 1.465-.568 1.465-1.482 0-.895-.639-1.495-1.5-1.495Zm-.017 2.152h-.598v-1.326h.598c.369 0 .604.288.604.663-.001.376-.235.663-.604.663ZM18.374 16.863c-.251 0-.374-.219-.374-.431 0-.22.128-.432.374-.432h1.431a.47.47 0 0 1 .042 0h1.43c.247 0 .375.212.375.432 0 .212-.123.431-.374.431H20.25v3.141c0 .314-.179.488-.419.488h-.01c-.24 0-.42-.174-.42-.488v-3.14h-1.027Z",
  fill: "#fff"
}));
export default SvgPptDocument;
