import React from "react";
import { useIntl } from "react-intl";

export const FlashMessageWrapper = () => {
  const { messages } = useIntl();

  return (
    <div className="absolute top-0 flex flex-col md:flex-row items-center justify-center w-full py-2 px-6 text-center space-x-2 bg-blue-60 text-white z-10">
      <p className="mb-0">{messages?.common?.["newVersionAvailable"]}</p>
      <button
        className="font-manrope inline-block py-0 px-1 m-0 font-bold bg-transparent border-none outline-none text-white"
        onClick={() => window.location.reload()}
      >
        {" "}
        {messages?.common?.["clickHereToRefresh"]}
      </button>
    </div>
  );
};
