import * as React from "react";
const SvgComponents = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M8.938 3.555 3.557 8.936a1.5 1.5 0 0 0 0 2.12l5.377 5.378a1.5 1.5 0 0 0 2.12 0l5.382-5.381a1.5 1.5 0 0 0 0-2.12L11.06 3.554a1.5 1.5 0 0 0-2.121 0Z",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgComponents;
