import moment from "moment-timezone";
import { atom } from "recoil";

import getEnums from "$/settings/enums";
import GROUP_BY from "$/settings/enums/analytics/_group-by.json";

const GroupByEnums = getEnums(GROUP_BY, "reference");

export const globalFacilityAtom = atom({
  key: "globalFacility",
  default: {
    totalFacilities: 0,
    isEditEabled: false,
    isDrawerOpen: false,
  },
});

export const globalMachineAtom = atom({
  key: "globalMachine",
  default: {
    totalMachines: 0,
    isDrawerOpen: false,
    isEditEabled: false,
    showWithoutSaveAlert: false,
  },
});

export const globalOpenTicketsAtom = atom({
  key: "globalOpenTickets",
  default: {
    totalOpenTickets: 0,
    totalUnreadTickets: 0,
    isDrawerOpen: false,
    showWithoutSaveAlert: false,
    currentStatus: "",
    isPartsRequest: false,
    newTicket: {
      selectedFacility: [],
      selectedMachine: [],
      selectedReporter: [],
      ticketTitle: "",
      ticketDescription: "",
      ticketType: null,
      submitButtonDisabled: true,

      errors: {
        facilityError: "",
        machineError: "",
        reporterError: "",
        ticketTitleError: "",
        ticketDescriptionError: "",
        ticketTypeError: "",
      },
    },
  },
});

export const globalSingleTicketAtom = atom({
  key: "globalSingleTicket",
  default: {
    totalSecondsSpent: 0,
    isAddTimeModalOpen: false,
  },
});

export const globalMyTicketsAtom = atom({
  key: "globalMyTickets",
  default: {
    totalOpenTickets: 0,
    totalClosedTickets: 0,
    isDrawerOpen: false,
  },
});

export const globalClosedTicketsAtom = atom({
  key: "globalClosedTickets",
  default: {
    totalClosedTickets: 0,
    isDrawerOpen: false,
  },
});

export const globalFacilityUsersAtom = atom({
  key: "globalFacilityUsers",
  default: {
    isEditEabled: false,
  },
});

export const globalOemUsersAtom = atom({
  key: "globalOemUsers",
  default: {
    users: {},
    isDrawerOpen: false,
    showWithoutSaveAlert: false,
    newUser: {},
  },
});

export const globalErrorAtom = atom({
  key: "globalErrorAtom",
  default: {
    isErrorAlertOpen: false,
  },
});

export const globalMaintenanceDocumentsAtom = atom({
  key: "globalMaintenanceDocumentsAtom",
  default: {
    totalDoc: 0,
  },
});

export const globalMachineTemplateAtom = atom({
  key: "globalMachineTemplate",
  default: {
    totalMachineTemplates: 0,
    isDrawerOpen: false,
    showWithoutSaveAlert: false,
    isEditEnabled: false,
  },
});

export const timeSpanAtom = atom({
  key: "durationSpan",
  default: {
    label: "This Year",
    value: "thisYear",
    groupBy: GroupByEnums.MONTH,
    startDate: moment.utc().startOf("year").toISOString(),
    endDate: moment.utc().endOf("year").toISOString(),
  },
});

export const globalInventoryAtom = atom({
  key: "globalInventoryAtom",
  default: {
    isCreateDrawerOpen: false,
    isPreviewDrawerOpen: false,
  },
});

export const globalAppVersionAtom = atom({
  key: "globalAppVersionAtom",
  default: {
    minimumAppVersionSupported: "",
    showFlashMessage: false,
  },
});
