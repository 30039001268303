import * as React from "react";
const SvgImageDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#00B09B"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M21.61 22H9.8a.4.4 0 0 1-.32-.64l3.692-4.923a.4.4 0 0 1 .646.008L16 19.5l2.203-1.763a.4.4 0 0 1 .55.047l3.155 3.55a.4.4 0 0 1-.299.666Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.017l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 17.5,
  cy: 14.5,
  r: 1.5,
  fill: "#fff"
}));
export default SvgImageDocument;
