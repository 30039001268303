import { bottom } from "@popperjs/core";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import Overlay from "@shared/ui/Overlay";
import { useState, useRef } from "react";
import { MessageFormatElement, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { sidebarAtom } from "#/src/atoms/sidebar";
import useAuth from "~/components/general/_use-auth";
import { EN_LOCALE } from "~/constants/locales";
import { settings } from "~/constants/settings";
import type { SubSettingRouteType } from "~/types/route";
import type { UserType } from "~/types/user";

const SubSettingNode = ({
  subSetting,
  pathname,
  messages,
  defaultLocale,
}: {
  subSetting: SubSettingRouteType;
  pathname: string;
  messages: Record<string, string> | Record<string, MessageFormatElement[]>;
  defaultLocale: string;
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const titleRef = useRef<HTMLDivElement>();

  return (
    <div>
      {/* @ts-ignore */}
      <Link to={subSetting.route}>
        <div
          className={`flex cursor-pointer p-sm space-x-sm items-center rounded-md ${
            pathname.startsWith(subSetting.route)
              ? "bg-accent"
              : "hover:bg-accent"
          }`}
          onMouseEnter={() =>
            defaultLocale !== EN_LOCALE && setShowOverlay(true)
          }
          onMouseLeave={() =>
            defaultLocale !== EN_LOCALE && setShowOverlay(false)
          }
          ref={(ref) => (titleRef.current = ref)}
        >
          {pathname.startsWith(subSetting.route) ? (
            <Label
              size={LABEL_SIZES.MEDIUM}
              color="text-brand"
              className="truncate"
            >
              {messages.settings?.["tabTitles"]?.[subSetting.titleKey]}
            </Label>
          ) : (
            <BodyText
              size={BODY_TEXT_SIZES.SMALL}
              color="text-secondary"
              className="truncate"
            >
              {messages.settings?.["tabTitles"]?.[subSetting.titleKey]}
            </BodyText>
          )}
        </div>
      </Link>
      <Overlay
        arrowClasses="before:bg-secondary before:shadow-alert"
        className="bg-secondary p-2 rounded shadow-alert w-fit z-10"
        containerRef={document.body}
        offset={[0, 10]}
        placement={bottom}
        showOverlay={showOverlay}
        targetRef={titleRef}
      >
        <BodyText color="text-inverse" size={BODY_TEXT_SIZES.X_SMALL}>
          {messages.settings?.["tabTitles"]?.[subSetting.titleKey]}
        </BodyText>
      </Overlay>
    </div>
  );
};

const MainSettingsNavigation = ({ isOverlay }: { isOverlay: boolean }) => {
  const { messages, defaultLocale } = useIntl();
  const { user } = useAuth() as { user: UserType };
  const { pathname } = useLocation();
  const sidebarExpanded = useRecoilValue(sidebarAtom);

  return (
    <nav
      className={`h-full overflow-hidden ${
        !sidebarExpanded && !isOverlay ? "w-0" : "w-56xl"
      } transition-all ease-in-out duration-300`}
    >
      <div
        className={`${
          isOverlay
            ? ""
            : "h-screen border-primary border-r border-0 border-solid"
        } overflow-y-auto ease-in-out flex flex-col w-56xl py-2xl px-lg space-y-2xl`}
      >
        {settings.map((setting) =>
          setting.enableIf(user) ? (
            <div className="space-y-md" key={setting.titleKey}>
              <Label size={LABEL_SIZES.MEDIUM}>
                {messages.settings?.["tabTitles"]?.[setting.titleKey]}
              </Label>
              <div className="pl-md space-y-3xs">
                {setting.subSettings.map((subSetting) =>
                  subSetting.enableIf(user) ? (
                    <SubSettingNode
                      subSetting={subSetting}
                      pathname={pathname}
                      messages={messages}
                      defaultLocale={defaultLocale}
                    />
                  ) : null,
                )}
              </div>
            </div>
          ) : null,
        )}
      </div>
    </nav>
  );
};

export default MainSettingsNavigation;
