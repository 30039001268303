import * as React from "react";
const SvgMachineTemplates = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_365_2089)",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "m6.562 11.692-2.87 2.87a.8.8 0 0 0 0 1.13L6.56 18.56a.8.8 0 0 0 1.131 0l2.87-2.869a.8.8 0 0 0 0-1.13l-2.868-2.868a.8.8 0 0 0-1.13 0ZM11.875 2.5v4.375h4.375"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.5 17.5h3.125a.624.624 0 0 0 .625-.625v-10L11.875 2.5h-7.5a.625.625 0 0 0-.625.625v7.5"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_365_2089"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgMachineTemplates;
