import * as React from "react";
const SvgRtfDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#FF7A00"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14.043 20.793a.367.367 0 1 1-.66.32l-.631-1.299H11.72v1.146a.36.36 0 0 1-.72 0V17.5a.5.5 0 0 1 .5-.5h1.288c.084 0 .228.006.342.024.75.114 1.104.666 1.104 1.38 0 .582-.24 1.062-.732 1.278l.541 1.11Zm-2.323-3.115v1.458h1.038c.078 0 .186-.006.27-.03.36-.09.474-.426.474-.702 0-.276-.114-.606-.474-.696a1.065 1.065 0 0 0-.27-.03H11.72ZM16.242 21.32a.363.363 0 0 1-.363-.363v-3.279h-1.06a.339.339 0 0 1 0-.678h2.845a.339.339 0 1 1 0 .678h-1.06v3.279c0 .2-.162.363-.362.363ZM18.726 21.32a.36.36 0 0 1-.36-.36V17.5a.5.5 0 0 1 .5-.5h1.738a.36.36 0 0 1 0 .72h-1.518v1.08h1.158a.36.36 0 0 1 0 .72h-1.159v1.44a.36.36 0 0 1-.36.36Z",
  fill: "#fff"
}));
export default SvgRtfDocument;
