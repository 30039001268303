import * as React from "react";
const SvgFolderFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3120)"
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip1_66_3120)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M18.898 8.64a1.266 1.266 0 0 0-1.015-.515h-1.008v-1.25a1.25 1.25 0 0 0-1.25-1.25h-5.414L8.039 4a1.219 1.219 0 0 0-.75-.25H3.125A1.25 1.25 0 0 0 1.875 5v11.25a.617.617 0 0 0 .625.625h13.75a.625.625 0 0 0 .594-.43l2.226-6.672a1.273 1.273 0 0 0-.172-1.132ZM7.29 5l2.172 1.625c.215.164.48.252.75.25h5.414v1.25H5.453a1.25 1.25 0 0 0-1.187.852l-1.141 3.421V5h4.164Z",
  fill: "#0517F8"
})), /* @__PURE__ */ React.createElement("path", {
  d: "M2.5 16V5a.625.625 0 0 1 .625-.625h4.164c.135 0 .267.044.375.125l2.172 1.625c.108.08.24.124.375.125h5.414a.625.625 0 0 1 .625.625V8.75",
  stroke: "#0517F8",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3120"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
})), /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip1_66_3120"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgFolderFilled;
