import { useMutation } from "@apollo/client";

import client from "../../apollo/_client";

import { errorsHandler } from "#/src/transformers";
import { GET_INVENTORY_PART, InventoryPart } from "~/api";
import {
  CREATE_OWN_OEM_INVENTORY_PART_MUTATION,
  DELETE_OWN_OEM_INVENTORY_PART_MUTATION,
  UPDATE_OWN_OEM_INVENTORY_PART_MUTATION,
} from "~/api/_mutations";

export const addPart = () => {
  const [createOwnOemInventoryPart, { error = {}, loading }] = useMutation(
    CREATE_OWN_OEM_INVENTORY_PART_MUTATION,
  );
  return {
    create: (input) =>
      new Promise((resolve, reject) => {
        return createOwnOemInventoryPart({
          variables: {
            input,
          },
          update(cache, { data: { createOwnOemInventoryPart: ref } }) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemInventoryPart",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const updateParts = () => {
  const [updatePart, { error, loading }] = useMutation(
    UPDATE_OWN_OEM_INVENTORY_PART_MUTATION,
  );
  return {
    update: (input) =>
      new Promise((resolve, reject) => {
        updatePart({
          variables: {
            input,
          },
          update(cache, { data: { updateOwnOemInventoryPart: ref } }) {
            cache.modify({
              id: cache.identify({ __typename: "InventoryPart", id: ref._id }),
              fields: {
                listOwnOemInventoryPart(existingRefs = []) {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: InventoryPart.fragments.InventoryPartBasicData,
                    fragmentName: "InventoryPartBasicData",
                  });

                  return [...existingRefs, newRef];
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const deleteParts = () => {
  const [deletePart, { loading, error }] = useMutation(
    DELETE_OWN_OEM_INVENTORY_PART_MUTATION,
  );
  return {
    delete: (partId) =>
      deletePart({
        variables: {
          id: partId,
        },
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemInventoryPart",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemMachineById",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemComponents",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemComponentById",
          });
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const updateInventoryPartCache = (payload) => {
  const inventoryPart = client.readQuery({
    query: GET_INVENTORY_PART,
    variables: { id: payload._id },
  });

  if (!inventoryPart) return;

  const { getInventoryPart: cachedInventoryPart } = inventoryPart;
  if (cachedInventoryPart) {
    const cloned = { ...cachedInventoryPart };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_INVENTORY_PART,
      variables: { id: payload._id },
      data: {
        getInventoryPart: cloned,
      },
    });
  }
};
