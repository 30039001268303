import * as React from "react";
const SvgSettings = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#sidebar-settings-a)",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M16 19.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.352 11.086c.197.182.385.37.562.562l2.133.305c.347.604.615 1.25.797 1.922l-1.297 1.727s.023.53 0 .796l1.297 1.727a7.987 7.987 0 0 1-.797 1.922l-2.133.305s-.367.382-.562.562l-.305 2.133a8.066 8.066 0 0 1-1.922.797l-1.727-1.297a4.53 4.53 0 0 1-.796 0l-1.727 1.297a7.988 7.988 0 0 1-1.922-.797l-.305-2.133c-.192-.182-.38-.37-.562-.562l-2.133-.305a8.061 8.061 0 0 1-.797-1.922l1.297-1.727s-.023-.53 0-.796l-1.297-1.727c.18-.673.449-1.319.797-1.922l2.133-.305c.182-.192.37-.38.562-.562l.305-2.133a8.061 8.061 0 0 1 1.922-.797l1.727 1.297c.265-.023.531-.023.796 0l1.727-1.297c.673.18 1.319.449 1.922.797l.305 2.133Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "sidebar-settings-a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(6 6)",
  d: "M0 0h20v20H0z"
}))));
export default SvgSettings;
