import getEnums from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

export const ROUTES_TO_FEATURE_FLAG_MAP = {
  "/app/work": {
    getFeatureDeniedMessage: (messages: {
      menus: {
        [key: string]: { featureAccessDeniedModalTitle: string };
      };
    }) => messages.menus?.["workManagement"].featureAccessDeniedModalTitle,
    flag: paidFeatures.workManagement,
  },
};
