import * as React from "react";
const SvgDocDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#6974FB"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11.033 16H9.986a.463.463 0 0 0-.486.485v3.522c0 .36.29.472.498.472h1.099c1.3 0 2.159-.856 2.159-2.177 0-1.396-.81-2.302-2.223-2.302Zm.05 3.61h-.638V16.87h.575c.871 0 1.25.584 1.25 1.39 0 .755-.372 1.352-1.187 1.352ZM18.25 18.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-3.528 0a1.278 1.278 0 1 0 2.556 0 1.278 1.278 0 0 0-2.556 0ZM22.37 17.139c.209-.17.243-.48.033-.648a2.25 2.25 0 1 0 .122 3.413c.197-.182.14-.49-.08-.643-.22-.154-.52-.09-.744.058a1.278 1.278 0 1 1-.078-2.185c.234.131.539.174.747.005Z",
  fill: "#fff"
}));
export default SvgDocDocument;
