import * as React from "react";
const SvgPdfDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  fill: "none",
  viewBox: "0 0 20 20",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M4.375 2.5h7.965l4.223 4.206v9.857c0 .518-.42.937-.938.937H4.375a.938.938 0 0 1-.938-.938V3.438c0-.517.42-.937.938-.937Z",
  fill: "#EE0047"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.549 6.719H13.28a.938.938 0 0 1-.937-.938V2.51l4.205 4.21Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.046 10.54c.157 0 .234-.137.234-.27 0-.137-.08-.27-.234-.27h-.894c-.175 0-.273.145-.273.305v2.198c0 .196.112.304.263.304.15 0 .262-.108.262-.304v-.604h.54c.169 0 .253-.137.253-.273 0-.134-.084-.267-.252-.267h-.541v-.82h.642ZM10.023 10H9.37a.29.29 0 0 0-.304.303v2.201c0 .225.181.295.311.295h.687c.813 0 1.35-.534 1.35-1.36 0-.873-.506-1.439-1.39-1.439Zm.032 2.257h-.4v-1.714h.36c.544 0 .781.365.781.869 0 .471-.232.845-.741.845ZM7.657 10H7.01c-.183 0-.285.12-.285.305v2.198c0 .196.117.304.274.304.158 0 .275-.108.275-.304v-.642h.406c.502 0 .916-.355.916-.927 0-.559-.4-.934-.938-.934Zm-.01 1.345h-.374v-.829h.374c.23 0 .377.18.377.415 0 .234-.147.414-.377.414Z",
  fill: "#fff"
}));
export default SvgPdfDocument;
