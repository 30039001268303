import * as React from "react";
const SvgLxmlDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#00B09B"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.2 20.32a.5.5 0 0 1-.5-.5v-3.46a.36.36 0 0 1 .72 0v3.282h1.57a.339.339 0 0 1 0 .678H9.2ZM12.048 20.32a.368.368 0 0 1-.305-.572l1.08-1.612-1.043-1.567a.366.366 0 1 1 .611-.403l.882 1.352.863-1.347a.37.37 0 1 1 .62.405l-1.039 1.56 1.077 1.612a.368.368 0 1 1-.615.403l-.906-1.403-.917 1.405a.368.368 0 0 1-.308.167ZM15.876 20.32a.339.339 0 0 1-.34-.339v-3.582a.4.4 0 0 1 .758-.176l1.39 2.837 1.386-2.836a.399.399 0 0 1 .757.175v3.582a.339.339 0 0 1-.678 0v-2.433L17.89 20.19a.228.228 0 0 1-.412 0l-1.264-2.642v2.433a.339.339 0 0 1-.34.339ZM21.11 20.32a.5.5 0 0 1-.5-.5v-3.46a.36.36 0 0 1 .72 0v3.282h1.569a.339.339 0 1 1 0 .678h-1.79Z",
  fill: "#fff"
}));
export default SvgLxmlDocument;
