import React from "react";
import ReactDOM from "react-dom";

export const ErrorAlertBox = ({
  title,
  onAccept,
  acceptButtonText,
  overlay = true,
  isOpen = false,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      id="error-alert-box"
      className={`alert-modal-overlay${overlay ? " active" : ""}`}
    >
      <div className="alert-box-wrapper">
        <div className="alert-box-content">
          <h2 className="alert-box-title">{title}</h2>
          <div className="alert-box-buttons-group">
            {onAccept && (
              <button
                type="button"
                className={"btn-v2 primary-btn-v2"}
                onClick={onAccept}
              >
                {acceptButtonText || "Reload"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(".error-alert-box-modal-portal"),
  );
};
