import * as React from "react";
const SvgFacilitiesActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 6a6 6 0 0 1 6-6h20a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z",
  fill: "#fff",
  fillOpacity: 0.1
}), /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#facilities-active-a)"
}, /* @__PURE__ */ React.createElement("mask", {
  id: "b",
  maskUnits: "userSpaceOnUse",
  x: 16.263,
  y: 12.429,
  width: 8,
  height: 11,
  fill: "#000"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M16.263 12.429h8v11h-8z"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M18.158 13.429c-.494 0-.895.4-.895.894V22h6.015v-7.677c0-.494-.4-.894-.894-.894h-4.226Zm1.41 1.836a.5.5 0 1 0 0 1h1.406a.5.5 0 0 0 0-1h-1.406Z"
})), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M18.158 13.429c-.494 0-.895.4-.895.894V22h6.015v-7.677c0-.494-.4-.894-.894-.894h-4.226Zm1.41 1.836a.5.5 0 1 0 0 1h1.406a.5.5 0 0 0 0-1h-1.406Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17.263 22h-.895v.895h.895V22Zm6.015 0v.895h.895V22h-.895Zm-5.12-7.677v-1.79a1.79 1.79 0 0 0-1.79 1.79h1.79Zm0 7.677v-7.677h-1.79V22h1.79Zm5.12-.895h-6.015v1.79h6.015v-1.79Zm-.894-6.782V22h1.789v-7.677h-1.79Zm0 0h1.789a1.79 1.79 0 0 0-1.79-1.79v1.79Zm-4.226 0h4.226v-1.79h-4.226v1.79Zm1.804 1.442a.395.395 0 0 1-.394.395v-1.79c-.77 0-1.395.625-1.395 1.395h1.79Zm-.394-.394c.218 0 .394.176.394.394h-1.79c0 .77.625 1.395 1.396 1.395v-1.79Zm1.406 0h-1.406v1.79h1.406v-1.79Zm-.395.394c0-.218.177-.394.395-.394v1.79c.77 0 1.394-.625 1.394-1.396H20.58Zm.395.395a.395.395 0 0 1-.395-.395h1.79c0-.77-.625-1.394-1.395-1.394v1.79Zm-1.406 0h1.406v-1.79h-1.406v1.79Z",
  fill: "#2F415F",
  mask: "url(#b)"
}), /* @__PURE__ */ React.createElement("mask", {
  id: "c",
  maskUnits: "userSpaceOnUse",
  x: 7.842,
  y: 9,
  width: 11,
  height: 14,
  fill: "#000"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M7.842 9h11v14h-11z"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M9.737 10c-.494 0-.895.4-.895.895V22h8.421V10.895c0-.494-.4-.895-.895-.895H9.737Zm2.131 2.571a.5.5 0 1 0 0 1h2.369a.5.5 0 1 0 0-1h-2.369Z"
})), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M9.737 10c-.494 0-.895.4-.895.895V22h8.421V10.895c0-.494-.4-.895-.895-.895H9.737Zm2.131 2.571a.5.5 0 1 0 0 1h2.369a.5.5 0 1 0 0-1h-2.369Z",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.842 22h-.895v.895h.895V22Zm8.421 0v.895h.895V22h-.895ZM9.737 10.895v-1.79a1.79 1.79 0 0 0-1.79 1.79h1.79Zm0 11.105V10.895h-1.79V22h1.79Zm7.526-.895h-8.42v1.79h8.42v-1.79Zm-.895-10.21V22h1.79V10.895h-1.79Zm0 0h1.79a1.79 1.79 0 0 0-1.79-1.79v1.79Zm-6.631 0h6.631v-1.79H9.737v1.79Zm2.526 2.176a.395.395 0 0 1-.395.395v-1.79c-.77 0-1.394.625-1.394 1.395h1.79Zm-.395-.394c.218 0 .395.176.395.394h-1.79c0 .77.625 1.395 1.395 1.395v-1.79Zm2.369 0h-2.369v1.79h2.369v-1.79Zm-.395.394c0-.218.177-.394.395-.394v1.79c.77 0 1.395-.625 1.395-1.396h-1.79Zm.395.395a.395.395 0 0 1-.395-.395h1.79c0-.77-.625-1.394-1.395-1.394v1.79Zm-2.369 0h2.369v-1.79h-2.369v1.79Z",
  fill: "#2F415F",
  mask: "url(#c)"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8 22h16",
  stroke: "#fff",
  strokeWidth: 1.3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "facilities-active-a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(6 6)",
  d: "M0 0h20v20H0z"
}))));
export default SvgFacilitiesActive;
