import * as React from "react";
const SvgDocumentsFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3129)",
  fill: "#0517F8"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M2 4h5l1 2H2V4Z"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16 5.5H9.383L7.25 3.367A1.242 1.242 0 0 0 6.367 3H2.25A1.25 1.25 0 0 0 1 4.25v11.297a1.203 1.203 0 0 0 1.203 1.203h5.719a.625.625 0 0 0 0-1.25H2.25V6.75H16V8a.625.625 0 1 0 1.25 0V6.75A1.25 1.25 0 0 0 16 5.5ZM6.367 4.25l1.25 1.25H2.25V4.25h4.117Z"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M3 15.95V7h12.44a1 1 0 0 1 1 1v3.475L9.72 15.95H3Z",
  stroke: "#0517F8",
  strokeWidth: 1.6
}), /* @__PURE__ */ React.createElement("path", {
  d: "m15.759 14.182 1.758-1.453a.633.633 0 0 0-.352-1.11l-2.328-.18-.898-2.07a.626.626 0 0 0-1.141 0l-.898 2.07-2.329.18a.633.633 0 0 0-.351 1.11l1.758 1.453-.531 2.164a.617.617 0 0 0 .234.648.626.626 0 0 0 .687.04l2-1.18 2 1.18a.68.68 0 0 0 .32.085.578.578 0 0 0 .368-.125.617.617 0 0 0 .234-.648l-.531-2.164Z",
  stroke: "#fff",
  strokeWidth: 1.2
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3129"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgDocumentsFilled;
