const OEM_ROLES = {
  OWNER: "OWNER",
  OEM: "OEM",
  STAFF: "STAFF",
  TECHNICIAN: "TECHNICIAN",
} as const;

const OEM_ADMIN_ROLES: (typeof OEM_ROLES)[keyof typeof OEM_ROLES][] = [
  OEM_ROLES.OWNER,
  OEM_ROLES.OEM,
];

const FACILITY_ROLES = {
  USER: "USER",
  GMU: "GMU",
} as const;

const USER_ROLES = {
  ...OEM_ROLES,
  ...FACILITY_ROLES,
} as const;

const ROLE_CATEGORIES_CONSTS = {
  OEM_ADMIN: "OEM_ADMIN",
  OEM_STAFF: "OEM_STAFF",
  FACILITY: "FACILITY",
} as const;

export type Role = keyof typeof USER_ROLES;
type RoleCategory = keyof typeof ROLE_CATEGORIES_CONSTS;

const ROLE_CATEGORIES: Record<RoleCategory, Role[]> = {
  OEM_ADMIN: [...OEM_ADMIN_ROLES],
  OEM_STAFF: Object.values(OEM_ROLES).filter(
    (role) => !OEM_ADMIN_ROLES.includes(role),
  ),
  FACILITY: Object.values(FACILITY_ROLES).map((role) => role),
};

const getRoleCategory = (role: Role): RoleCategory | undefined => {
  for (const category in ROLE_CATEGORIES) {
    if (ROLE_CATEGORIES[category as RoleCategory].includes(role as Role)) {
      return category as RoleCategory;
    }
  }
  return undefined;
};

export const useRoleManager = (initialRole: Role) => {
  const isOemAdmin: boolean =
    getRoleCategory(initialRole) === ROLE_CATEGORIES_CONSTS.OEM_ADMIN;

  const checkIfOemAdmin = (role: Role): boolean =>
    getRoleCategory(role) === ROLE_CATEGORIES_CONSTS.OEM_ADMIN;

  const isOemStaff: boolean =
    getRoleCategory(initialRole) === ROLE_CATEGORIES_CONSTS.OEM_STAFF;

  const isOemTechnician: boolean = initialRole === OEM_ROLES.TECHNICIAN;

  const checkIfOemStaff = (role: Role): boolean =>
    getRoleCategory(role) === ROLE_CATEGORIES_CONSTS.OEM_ADMIN;

  return {
    isOemAdmin,
    isOemStaff,
    checkIfOemAdmin,
    checkIfOemStaff,
    isOemTechnician,
  };
};

// For non-React components
export const isOemAdmin = (role: Role | string) =>
  role && getRoleCategory(role as Role) === ROLE_CATEGORIES_CONSTS.OEM_ADMIN;

export const isTechnician = (role: Role | string) =>
  role && role === OEM_ROLES.TECHNICIAN;
