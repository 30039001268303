import * as React from "react";
const SvgContactSupport = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 220 220",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#F3F4F6",
  d: "M214.713 60.576c-6.985-3.817-10.153-.638-12.694-3.817-2.541-3.179 6.985-11.429-12.1-19.679 0 0-1.265-17.776-27.302-18.414-26.037-.638-19.019 17.776-26.004 22.22-6.985 4.444-8.888-2.497-15.873 1.903-6.985 4.4-3.806 13.332-9.515 14.597-5.709 1.265-13.343-5.709-20.328-1.265-6.985 4.444-6.985 8.25-10.153 8.25s-9.526-2.54-13.332 0c-3.806 2.541-8.26 5.72-8.26 5.72H219.75s1.947-5.709-5.038-9.515Zm-209.426 132c6.985-3.817 10.153-.638 12.694-3.817 2.54-3.179-6.985-11.429 12.1-19.679 0 0 1.265-17.776 27.302-18.414 26.037-.638 19.019 17.776 26.004 22.22 6.985 4.444 8.888-2.497 15.873 1.903 6.985 4.4 3.806 13.332 9.515 14.597 5.709 1.265 13.343-5.709 20.328-1.265 6.985 4.444 6.985 8.25 10.153 8.25s9.526-2.541 13.332 0c3.806 2.541 8.261 5.72 8.261 5.72H.249s-1.947-5.709 5.038-9.515Z"
}), /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#a)"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#CDD1FE",
  d: "M107.8 202.35v-80.3h-9.9v80.3"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M107.8 202.35v-80.3h-9.9v80.3"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#E6E8FE",
  d: "M114.4 202.35v-71.5h-9.9v71.5"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M114.4 202.35v-71.5h-9.9v71.5"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M71.306 54.887h76.506L115.047 135.8H42.178a3.824 3.824 0 0 1-3.823-3.824V87.829a32.942 32.942 0 0 1 32.951-32.942Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#E6E8FE",
  d: "M114.831 121.715v14.046H42.179a3.824 3.824 0 0 1-3.824-3.824V88.203c0-1.223 1.003-2.152 2.226-2.152h74.232l.018 35.664Z",
  style: {
    mixBlendMode: "multiply"
  }
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M147.333 54.887c-8.62 0-16.888 3.469-22.983 9.642-6.095 6.174-9.52 14.548-9.52 23.279v47.924h65.005V87.798c-.003-8.73-3.428-17.1-9.523-23.272-6.095-6.172-14.361-9.639-22.979-9.639Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#E6E8FE",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M180 127h-65v8h65v-8Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M77.228 47.067h10.291V33.5H68.748a.412.412 0 0 0-.357.618l3.125 5.416a1.5 1.5 0 0 1 0 1.499l-3.125 5.416a.412.412 0 0 0 .357.618h8.48Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M88 28h6v55h-6V28Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M90.617 94.014a5.736 5.736 0 1 1 0-11.471 5.736 5.736 0 0 1 0 11.471Z"
})), /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2.201,
  d: "M201.419 114.607V86l-70.164 12.158c-1.198.208-1.223 1.92-.031 2.163l70.195 14.286Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#0517F8",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2.201,
  d: "m197.206 90.82 3.972 23.27-70.27-14.028c-1.236-.247-1.154-2.04.098-2.173l66.2-7.07Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  stroke: "#172B4D",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2.201,
  d: "m197.206 90.82-7.383 14.754-58.896-5.745c-1.321-.129-1.326-2.054-.006-2.19l66.285-6.82Z"
}), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M37 22h145v180H37z"
}))));
export default SvgContactSupport;
