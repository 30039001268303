const Broadcaster = () => {
  return {
    subscribers: {},

    subscribe(topic, action) {
      this.subscribers[topic] = this.subscribers[topic] || [];
      this.subscribers[topic].push(action);

      return (event, action) => {
        if (!this.subscribers[event]) return;

        this.subscribers[event] = this.subscribers[event].filter(
          (fn) => fn.toString() !== action.toString(),
        );
      };
    },

    publish(topic, ...args) {
      if (!this.subscribers[topic]) return;

      for (const action of this.subscribers[topic]) {
        action(...args);
      }
    },
  };
};

export const broadcaster = Broadcaster();
