import * as React from "react";
const SvgAiAssistant = (props) => /* @__PURE__ */ React.createElement("svg", {
  fill: "none",
  viewBox: "0 0 20 20",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7.14 11.25h3.21m.275.833-1.35-4.166h-1.05l-1.35 4.166m6.042 0V7.917",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M2.5 10a7.46 7.46 0 0 0 1.359 4.297l-.527 2.37 2.37-.526A7.497 7.497 0 1 0 2.5 10v0Z",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2
}));
export default SvgAiAssistant;
