import { useMutation } from "@apollo/client";
import { TimeTracker } from "../../api/_fragments";

import TYPES from "$/settings/enums/timeTracker/_type.json";
import { getEnums } from "../../utils";
import {
  CREATE_TIME_TRACKER_TAG_MUTATION,
  DELETE_TIME_TRACKER_TAG_MUTATION,
  UPDATE_TIME_TRACKER_TAG_MUTATION,
} from "../../api/_mutations";

const Types = getEnums(TYPES, "reference");

export const createTimeTrackerTag = () => {
  const [create, { error, loading }] = useMutation(
    CREATE_TIME_TRACKER_TAG_MUTATION,
  );

  return {
    addTimeTrackerTag: async (input) =>
      create({
        variables: { input: { ...input, type: Types.tickets } },
        update(cache, { data: { createOwnOemTimeTrackerTag: ref } }) {
          cache.modify({
            fields: {
              listOwnOemTimeTrackers(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: TimeTracker.fragments.TimeTrackerBasicData,
                  fragmentName: "TimeTrackerBasicData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),

    addTimeTrackerInProgress: loading,
    timeTrackerAddError: error,
  };
};

export const editTimeTrackerTag = () => {
  const [update, { error, loading }] = useMutation(
    UPDATE_TIME_TRACKER_TAG_MUTATION,
  );

  return {
    updateTimeTrackerTag: async (input) =>
      update({
        variables: { input: { ...input } },
        skip: !input?._id,
        update(cache, { data: { updateOwnOemTimeTrackerTag: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "TimeTracker", id: ref._id }),
            fields: {
              listOwnOemTimeTrackers(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: TimeTracker.fragments.TimeTrackerBasicData,
                  fragmentName: "TimeTrackerBasicData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),

    updateTimeTrackerInProgress: loading,
    timeTrackerUpdateError: error,
  };
};

export const deleteTimeTrackerTag = () => {
  const [deleteTag, { deletingTimeTrackerTag, timeTrackerDeleteError }] =
    useMutation(DELETE_TIME_TRACKER_TAG_MUTATION);

  return {
    removeTimeTrackerTag: (timeTrackerId) =>
      deleteTag({
        variables: {
          id: timeTrackerId,
        },
        update(cache) {
          cache.modify({
            fields: {
              listOwnOemTimeTrackers(existingRefs = [], { readField }) {
                return [...existingRefs].filter(
                  (itemRef) => readField("_id", itemRef) !== timeTrackerId,
                );
              },
            },
          });
        },
      }),
    deletingTimeTrackerTag,
    timeTrackerDeleteError,
  };
};
