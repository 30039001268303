import { bottom } from "@popperjs/core";
import { DOMAttributes, HTMLAttributes } from "react";
import ReactOverlay, { OverlayProps } from "react-overlays/Overlay";

import OverlayWrapper from "./OverlayWrapper";

type PropsType = {
  arrowClasses?: HTMLAttributes<HTMLDivElement>["className"];
  children: DOMAttributes<HTMLDivElement>["children"];
  className?: HTMLAttributes<HTMLDivElement>["className"];
  containerRef: OverlayProps["container"];
  offset: OverlayProps["offset"];
  placement?: OverlayProps["placement"];
  rootClose?: OverlayProps["rootClose"];
  showOverlay: OverlayProps["show"];
  targetRef: OverlayProps["target"];
};

const Overlay = ({
  arrowClasses,
  children,
  className,
  containerRef,
  offset,
  placement = bottom,
  rootClose = true,
  showOverlay,
  targetRef,
}: PropsType) => (
  <ReactOverlay
    container={containerRef}
    offset={offset}
    placement={placement}
    rootClose={rootClose}
    show={showOverlay}
    target={targetRef}
  >
    {OverlayWrapper({ arrowClasses, children, className })}
  </ReactOverlay>
);

export default Overlay;
