import { useMutation } from "@apollo/client";
import { getAllRoles } from "@shared/utils/getAllRoles";

import {
  CREATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  CURRENT_USER_QUERY,
  LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
  REMOVE_USER_BOARD,
  UPDATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  UPDATE_PERSONAL_SETTINGS,
  ADD_USER_BOARD,
  ASSIGN_MULTIPLE_SKILLS_USER_MUTATION,
} from "../../api";

const ALL_ROLES = getAllRoles();

export const useUpdateOemSupportAccount = () => {
  const [updateOemSupportAccount, { loading, error }] = useMutation(
    UPDATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  );

  return {
    loading,
    error,
    updateOemSupportAccount: async (input) =>
      updateOemSupportAccount({
        variables: { input },
        update(cache) {
          const { currentUser } = cache.readQuery({
            query: CURRENT_USER_QUERY,
          });
          const { listOwnOemSupportAccounts } = cache.readQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
          });

          if (
            currentUser._id !== input._id &&
            input.role === ALL_ROLES.OWNER.VALUE &&
            currentUser.role === ALL_ROLES.OWNER.VALUE
          ) {
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: {
                currentUser: { ...currentUser, role: ALL_ROLES.OEM.VALUE },
              },
            });
            const newData = listOwnOemSupportAccounts.map((oemAccount) =>
              oemAccount._id === currentUser._id
                ? { ...oemAccount, role: ALL_ROLES.OEM.VALUE }
                : oemAccount,
            );

            cache.writeQuery({
              query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
              data: {
                listOwnOemSupportAccounts: newData,
              },
            });
          }
        },
      }),
  };
};

export const useCreateOemSupportAccount = () => {
  const [create, { loading, error }] = useMutation(
    CREATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  );
  return {
    loading,
    error,
    createOemSupportAccount: async (input) =>
      create({
        variables: {
          input,
        },
        update(cache, { data: { createOemSupportAccount: newUser } }) {
          const { listOwnOemSupportAccounts } = cache.readQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
          });

          const newData = [...listOwnOemSupportAccounts, newUser];

          cache.writeQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
            data: {
              listOwnOemSupportAccounts: newData,
            },
          });
        },
      }),
  };
};

export const useUpdatePersonalNotificationSettings = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PERSONAL_SETTINGS);

  return {
    loading,
    error,
    updatePersonalNotificationSettings: async (emailNotificationSettings) =>
      update({
        variables: {
          emailNotificationSettings,
        },
      }),
  };
};

export const useUpdatePersonalGeneralSettings = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PERSONAL_SETTINGS);

  return {
    loading,
    error,
    updatePersonalGeneralSettings: async (personalSettings) =>
      update({
        variables: {
          personalSettings,
        },
      }),
  };
};

export const useAddBoard = () => {
  const [update, { loading, error }] = useMutation(ADD_USER_BOARD);

  return {
    loading,
    error,
    addBoard: async (board) =>
      update({
        variables: { board },
        update(cache, { data: { addUserBoard: ref } }) {
          const { currentUser } =
            cache.readQuery({
              query: CURRENT_USER_QUERY,
            }) || {};

          if (currentUser) {
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: {
                currentUser: { ...currentUser, boards: [...ref.boards] },
              },
            });
          }
        },
      }),
  };
};

export const useRemoveBoard = () => {
  const [remove, { loading, error }] = useMutation(REMOVE_USER_BOARD);

  return {
    loading,
    error,
    removeBoard: async (board) =>
      remove({
        variables: { board },
        update(cache, { data: { removeUserBoard: ref } }) {
          const { currentUser } =
            cache.readQuery({
              query: CURRENT_USER_QUERY,
            }) || {};

          if (currentUser) {
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: {
                currentUser: { ...currentUser, boards: [...ref.boards] },
              },
            });
          }
        },
      }),
  };
};

export const useAssignSkillsUser = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_MULTIPLE_SKILLS_USER_MUTATION,
  );

  return {
    assignLoading: loading,
    error,
    assignSkills: async (input) =>
      assign({
        variables: { input },
        update(cache, { data: { assignMultipleSkillsToOemUser: ref } }) {
          const { listOwnOemSupportAccounts } = cache.readQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
          });

          let newData = [...listOwnOemSupportAccounts];
          const index = newData.findIndex((user) => user._id === ref._id);

          if (index >= 0) {
            newData[index] = {
              ...newData[index],
              skills: ref.skills,
            };
          }

          cache.writeQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
            data: {
              listOwnOemSupportAccounts: newData,
            },
          });
        },
      }),
  };
};
