import mixpanel from "mixpanel-browser";
import { isTrackingDisabled } from ".";

export const isMixpanelEnabled = () => {
  return (
    import.meta.env.VITE_APP_MIXPANEL_TOKEN &&
    import.meta.env.MODE !== "development" &&
    (import.meta.env.MODE === "production" || !isTrackingDisabled())
  );
};

export const registerMixpanelEvent = async (event, properties = {}) => {
  if (isMixpanelEnabled()) {
    mixpanel.track(event, { ...properties });
  }
};
