export const WORK_ORDERS_PAGE_VIEW = {
  LIST_VIEW: "LIST_VIEW",
  BOARD_VIEW: "BOARD_VIEW",
  MAP_VIEW: "MAP_VIEW",
  PLAN_CAPACITY_VIEW: "PLAN_CAPACITY_VIEW",
  TABLE_VIEW: "TABLE_VIEW",
};

export const WORK_ORDER_UPDATED_IDENTIFIER = "ticketUpdated";
export const WORK_ORDER_CREATED_IDENTIFIER = "ticketCreated";
export const WORK_ORDER_DELETED_IDENTIFIER = "ticketDeleted";

export const WORK_ORDER_BROADCAST_TOPICS = {
  REORDER_WORK_ORDER_LIST: "REORDER_WORK_ORDER_LIST",
  MEMBERSHIP_UPDATED: "MEMBERSHIP_UPDATED",
};

export const MEMBERSHIP_UPDATED_IDENTIFIER = "membershipsUpdated";

export const WORK_ORDER_SORTING_KEY = {
  TITLE: "title",
  CREATED_AT: "created_at",
};
