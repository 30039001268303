import * as React from "react";
const SvgAnalytics = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M8.882 18.155c.66.65.66 1.706 0 2.357-.66.65-1.728.65-2.388 0a1.652 1.652 0 0 1 0-2.357c.66-.651 1.729-.651 2.388 0",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M17.114 19.333c0-.92.755-1.666 1.688-1.666.933 0 1.688.745 1.688 1.666 0 .921-.755 1.667-1.688 1.667a1.67 1.67 0 0 1-1.688-1.654v-.013Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14.482 11.488c.659.651.659 1.706 0 2.357-.66.651-1.728.651-2.388 0a1.652 1.652 0 0 1 0-2.357c.66-.65 1.729-.65 2.388 0M25.506 11.488c.659.651.659 1.706 0 2.357-.66.651-1.729.651-2.388 0a1.652 1.652 0 0 1 0-2.357c.66-.65 1.728-.65 2.388 0M23.178 14.098l-3.01 3.576M9.048 17.87l3.136-3.751M17.697 17.674l-3.042-3.555",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAnalytics;
