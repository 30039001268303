import { useQuery } from "@apollo/client";
import { GET_APP_CONFIG } from "../../api";

export const useGetAppConfig = () => {
  const {
    data: { getAppConfig } = {},
    loading,
    error,
  } = useQuery(GET_APP_CONFIG, {
    fetchPolicy: "cache-and-network",
  });

  return {
    appConfig: getAppConfig,
    loading,
    error,
  };
};
