import * as React from "react";
const SvgAssetsManagement = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#assets-management-a)",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M8.778 14.036a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928M23.222 14.036a1.964 1.964 0 1 1 0 3.928 1.964 1.964 0 0 1 0-3.928M11.556 19.334l1.11 1.111M19.333 11.556l1.111 1.11M16 21.258a1.964 1.964 0 1 1 0 3.929 1.964 1.964 0 0 1 0-3.929M16 6.813a1.964 1.964 0 1 1 0 3.929 1.964 1.964 0 0 1 0-3.929M19.333 20.444l1.111-1.11M11.556 12.666l1.11-1.111"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "assets-management-a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(6 6)",
  d: "M0 0h19.999v20H0z"
}))));
export default SvgAssetsManagement;
