import * as React from "react";
const SvgMachines = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3160)",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M9.555 2.319 7.313 4.562a.625.625 0 0 0 0 .883l2.24 2.242c.245.244.64.244.885 0l2.242-2.243a.625.625 0 0 0 0-.884l-2.24-2.241a.625.625 0 0 0-.885 0ZM14.556 7.319l-2.243 2.243a.625.625 0 0 0 0 .883l2.242 2.242c.244.244.64.244.883 0l2.243-2.243a.625.625 0 0 0 0-.884L15.44 7.319a.625.625 0 0 0-.884 0ZM4.555 7.312 2.313 9.555a.625.625 0 0 0 0 .883l2.24 2.242c.245.244.64.244.885 0l2.242-2.243a.625.625 0 0 0 0-.884L5.44 7.312a.625.625 0 0 0-.885 0ZM9.556 12.312l-2.243 2.243a.625.625 0 0 0 0 .883l2.241 2.242c.245.244.64.244.884 0l2.243-2.243a.625.625 0 0 0 0-.884l-2.241-2.241a.625.625 0 0 0-.884 0Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3160"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgMachines;
