import * as React from "react";
const SvgOdtDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#EE0064"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.07 21.5c-1.296 0-2.07-.912-2.07-2.25S10.774 17 12.07 17c1.296 0 2.064.912 2.064 2.25s-.768 2.25-2.064 2.25Zm0-.684c.87.012 1.302-.624 1.302-1.566 0-.948-.432-1.56-1.302-1.566-.87-.012-1.302.618-1.302 1.566-.006.942.432 1.56 1.302 1.566ZM15.114 21.41a.5.5 0 0 1-.5-.5v-3.32a.5.5 0 0 1 .5-.5h.874c.072 0 .33 0 .528.024 1.032.126 1.59 1.038 1.59 2.136s-.558 2.004-1.59 2.136c-.198.024-.456.024-.528.024h-.874Zm.238-.684h.636c.126 0 .336 0 .48-.03.612-.12.876-.768.876-1.446 0-.702-.276-1.332-.876-1.446-.144-.03-.36-.03-.48-.03h-.636v2.952ZM19.98 21.41a.363.363 0 0 1-.364-.363v-3.279h-1.059a.339.339 0 0 1 0-.678h2.844a.339.339 0 1 1 0 .678h-1.059v3.279c0 .2-.162.363-.363.363Z",
  fill: "#fff"
}));
export default SvgOdtDocument;
