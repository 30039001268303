import * as React from "react";
const SvgMdDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#8940E5"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.089 20.32a.339.339 0 0 1-.339-.339v-3.582a.399.399 0 0 1 .758-.176l1.39 2.837 1.385-2.836a.399.399 0 0 1 .757.175v3.582a.339.339 0 0 1-.678 0v-2.433l-1.258 2.642a.228.228 0 0 1-.412 0l-1.264-2.642v2.433a.339.339 0 0 1-.339.339ZM17.263 20.32a.5.5 0 0 1-.5-.5V16.5a.5.5 0 0 1 .5-.5h.874c.072 0 .33 0 .528.024 1.032.126 1.59 1.038 1.59 2.136s-.558 2.004-1.59 2.136c-.198.024-.456.024-.528.024h-.874Zm.238-.684h.636c.126 0 .336 0 .48-.03.612-.12.876-.768.876-1.446 0-.702-.276-1.332-.876-1.446-.144-.03-.36-.03-.48-.03H17.5v2.952Z",
  fill: "#fff"
}));
export default SvgMdDocument;
