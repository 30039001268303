import { WarningCircle } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ContentLoading from "../ContentLoading";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LoginForm = ({ modifier, callback = () => {}, color }) => {
  const { messages: msg } = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    callback(data, setError);
  };

  const onError = () => {
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      clearErrors("root");
      setLoading(false);
    }

    return () => {
      mounted = false;
    };
  }, [errors.root]);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <div className="form-authHead">
        <Headline size={HEADLINE_SIZES.SMALL} className="mb-sm">
          {msg.login?.welcome}
        </Headline>
        <p className="form-authHeadLead">{msg.login?.subtitle}</p>
      </div>
      <div className="input-v2-wrapper auth-formGroup">
        <label htmlFor="email" className={"input-v2-label c-label"}>
          {msg.login?.email.label}
        </label>
        <input
          id="email"
          name="email"
          type="text"
          placeholder={msg.login?.email.placeholder}
          {...register("email", {
            required: {
              value: true,
              message: msg?.common?.errors.emailRequired,
            },
            pattern: {
              value: emailPattern,
              message: msg?.login?.email.invalidEmail,
            },
          })}
          className={`input-v2 auth-input ${
            errors?.email ? "input-v2-error" : ""
          }`}
        />

        {errors?.email && (
          <p className="input-v2-error-label">
            <WarningCircle className="input-v2-error-icon" size={16} />
            {errors?.email.message}
          </p>
        )}
      </div>
      <div className="input-v2-wrapper auth-formGroup">
        <label htmlFor="password" className={"input-v2-label c-label"}>
          {msg.login?.password.label}
        </label>
        <input
          id="password"
          name="password"
          type="password"
          placeholder={msg.login?.password.placeholder}
          {...register("password", {
            required: {
              value: true,
              message: msg?.login?.password.invalidPassword,
            },
          })}
          className={`input-v2 auth-input ${
            errors?.password ? "input-v2-error" : ""
          }`}
        />

        {errors?.password && (
          <p className="input-v2-error-label auth-formGroupError">
            <WarningCircle className="input-v2-error-icon" size={16} />
            {errors?.password.message}
          </p>
        )}

        <Link to={`/forgot-password${modifier ? "/" + modifier : ""}`}>
          <Button
            variant={BUTTON_VARIANTS.LINK}
            type="button"
            text={msg.login?.forgotPassword.title}
          />
        </Link>
      </div>

      <div className="form-group auth-submit">
        <button
          className={`c-button primary-icon-btn-v2 ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          onClick={() => {
            setLoading(true);
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading ? <ContentLoading /> : `${msg.login?.loginLabel}`}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
