import { Checks, WarningCircle, X } from "phosphor-react";
import React from "react";
import { toast } from "react-toastify";
import { DottedCircleArrowUp, ProcedureLoadingIcon } from "~/icons/icons";
import { TOAST_PROCESSING_TYPE } from "../constants";

const Toast = ({ message, type = "success", closeToast = () => {} }) => {
  // type can be success, warning, error

  return (
    <div className={`toast-v2 ${type}`}>
      <div className="toast-v2-content">
        <span className="toast-v2-icon">
          {type === "success" && <Checks size={16} />}
          {type === "error" && <WarningCircle size={16} />}
          {type === "uploading" && <DottedCircleArrowUp />}
          {type === TOAST_PROCESSING_TYPE && (
            <span className="u-inline-flex spinning">
              <ProcedureLoadingIcon />
            </span>
          )}
        </span>

        <p className="toast-v2-message">{message || "Success"}</p>
      </div>

      <button type="button" className="toast-v2-close-btn" onClick={closeToast}>
        <X size={16} />
      </button>
    </div>
  );
};

export const ToastFeed = ({ message, type }) => {
  return (
    <>
      {toast(<Toast type={type} message={message} />, {
        closeButton: false,
      })}
    </>
  );
};

export default Toast;
