// styles for v2
export const COLOR = {
  $blue_v2_100: "#0517f8",
  $blue_v2_60: "#6974fb",
  $blue_v2_20: "#cdd1fe",
  $blue_v2_10: "#e6e8fe",
  $gray_v2_100: "#172b4d",
  $gray_v2_60: "#748094",
  $gray_v2_40: "#a2aab8",
  $gray_v2_20: "#d1d5db",
  $gray_v2_10: "#e8eaed",
  $gray_v2_5: "#f3f4f6",
  $white_v2: "#fff",
  $green_v2_100: "#00b09b",
  $green_v2_60: "#66d0c3",
  $green_v2_20: "#ccefeb",
  $green_v2_10: "#e5f7f5",
  $red_v2_100: "#ee0064",
  $red_v2_50: "#f780b2",
  $red_v2_20: "#fccce0",
  $red_v2_10: "#fde5f0",
  $orange_v2_100: "#ff7a00",
  $orange_v2_20: "#ffe4cc",
  $mint_blue_v2_100: "#00a5bc",
  $mint_blue_v2_20: "#caf7fd",
  $yellow_v2_100: "#fad200",
  $yellow_v2_20: "#fef6cc",
  $purple_v2_100: "#8940e5",
};

export const TimeTrackerLabelColors = [
  {
    colorName: "Light gray",
    colorCode: "#A2AAB8",
  },
  {
    colorName: "Gray",
    colorCode: "#748094",
  },
  {
    colorName: "Brown",
    colorCode: "#B88346",
  },
  {
    colorName: "Yellow",
    colorCode: "#EEBA00",
  },
  {
    colorName: "Orange",
    colorCode: "#FF7A00",
  },
  {
    colorName: "Green",
    colorCode: "#00B09B",
  },
  {
    colorName: "Blue",
    colorCode: "#0517F8",
  },
  {
    colorName: "Purple",
    colorCode: "#9E71FF",
  },
  {
    colorName: "Pink",
    colorCode: "#FF76E9",
  },
  {
    colorName: "Red",
    colorCode: "#EE0064",
  },
];
