import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  allMembershipChannelsAtom,
  unreadChannelMessagesAtom,
} from "../atoms/_chat";
import { useAuth } from "../components/general";
import { WORK_ORDER_BROADCAST_TOPICS } from "../constants/workOrders";
import { broadcaster } from "../utils/_appBroadcast";
import usePubnubInstance from "./_pubnub-unread-messages";

const useNotification = () => {
  const { user, chat: chatInstance } = useAuth();

  const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);
  const allMembershipChannelsCoil = useRecoilValue(allMembershipChannelsAtom);

  const [unreadLabels, setUnreadLabels] = useState({
    openTickets: 0,
    myTickets: 0,
  });

  const { getAllUnreadMessageCount } = usePubnubInstance(chatInstance)();

  useEffect(() => {
    const allUnreadChannels = Object.keys(
      unreadChannelMessages?.channels || {},
    );
    const hasAllMembershipChannels =
      Object.keys(allMembershipChannelsCoil).length > 0;
    const isFollowersMyWorkOrdersAllowed =
      user?.oem?.allowFollowersMyWorkOrders;

    if (allUnreadChannels.length && hasAllMembershipChannels) {
      let myWorkOrdersUnreadCount = 0;
      const totalUnread = allUnreadChannels.reduce((accumulator, channel) => {
        const channelInfo = allMembershipChannelsCoil[channel]?.custom;
        const channelAssignees = JSON.parse(channelInfo?.assignees || "[]");
        const channelFollowers = JSON.parse(channelInfo?.followers || "[]");
        const isMyWorkOrder =
          channelAssignees.includes(user?._id) ||
          (isFollowersMyWorkOrdersAllowed &&
            channelFollowers.includes(user?._id));

        if (isMyWorkOrder) {
          myWorkOrdersUnreadCount += unreadChannelMessages.channels[channel];
        }

        return accumulator + unreadChannelMessages.channels[channel];
      }, 0);
      setUnreadLabels((prev) => ({
        ...prev,
        openTickets: totalUnread,
        myTickets: myWorkOrdersUnreadCount,
      }));
    }
  }, [
    unreadChannelMessages?.channels,
    allMembershipChannelsCoil,
    user?.oem?.allowFollowersMyWorkOrders,
  ]);

  useEffect(() => {
    // getAllUnreadMessageCount fetches all channels with unread count
    // and set unreadChannelMessages atom
    getAllUnreadMessageCount();
    const unsubscribeAssigneeFollowersUpdate = broadcaster.subscribe(
      WORK_ORDER_BROADCAST_TOPICS.MEMBERSHIP_UPDATED,
      getAllUnreadMessageCount,
    );

    return () => {
      unsubscribeAssigneeFollowersUpdate(
        WORK_ORDER_BROADCAST_TOPICS.MEMBERSHIP_UPDATED,
        getAllUnreadMessageCount,
      );
    };
  }, []);

  return { unreadLabels };
};

export default useNotification;
