import * as React from "react";
const SvgJsonDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#9E4B65"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.784 20.5c-.523 0-.914-.234-1.14-.62-.1-.171.017-.373.21-.42.194-.048.393.095.546.224.099.085.226.138.354.138.168 0 .39-.084.48-.288.06-.126.06-.3.06-.534v-2.544a.366.366 0 0 1 .732 0V19c0 .396.018.624-.144.906-.24.414-.636.594-1.098.594ZM13.408 20.5c-.794 0-1.42-.366-1.683-.983-.087-.203.068-.412.286-.445.203-.03.389.106.5.278.198.306.543.472.939.472.462 0 .894-.222.894-.618 0-.246-.156-.402-.48-.504l-1.116-.324c-.384-.114-.966-.348-.966-1.11 0-.834.702-1.266 1.584-1.26.726.005 1.278.315 1.56.874.102.202-.046.424-.268.462-.206.035-.394-.109-.517-.277-.176-.243-.47-.377-.787-.381-.432-.012-.81.21-.81.546 0 .324.336.444.624.522l.774.21c.42.12 1.158.33 1.158 1.188 0 .9-.81 1.35-1.692 1.35ZM17.46 20.5c-1.297 0-2.07-.912-2.07-2.25S16.162 16 17.46 16c1.295 0 2.063.912 2.063 2.25s-.768 2.25-2.064 2.25Zm0-.684c.87.012 1.301-.624 1.301-1.566 0-.948-.432-1.56-1.302-1.566-.87-.012-1.302.618-1.302 1.566-.006.942.432 1.56 1.302 1.566ZM20.37 20.41a.366.366 0 0 1-.367-.366v-3.478a.476.476 0 0 1 .873-.262l1.863 2.828v-2.676a.366.366 0 1 1 .732 0v3.479a.476.476 0 0 1-.872.26l-1.864-2.833v2.682a.366.366 0 0 1-.366.366Z",
  fill: "#fff"
}));
export default SvgJsonDocument;
