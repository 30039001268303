import * as React from "react";
const SvgCalendarBlankFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("mask", {
  id: "a",
  fill: "#fff"
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M3 2.125a2.5 2.5 0 0 0-2.5 2.5V5.75h15V4.625a2.5 2.5 0 0 0-2.5-2.5H3ZM15.5 7.25H.5v6.125a2.5 2.5 0 0 0 2.5 2.5h10a2.5 2.5 0 0 0 2.5-2.5V7.25Z"
})), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M3 2.125a2.5 2.5 0 0 0-2.5 2.5V5.75h15V4.625a2.5 2.5 0 0 0-2.5-2.5H3ZM15.5 7.25H.5v6.125a2.5 2.5 0 0 0 2.5 2.5h10a2.5 2.5 0 0 0 2.5-2.5V7.25Z",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M.5 5.75H-1v1.5H.5v-1.5Zm15 0v1.5H17v-1.5h-1.5Zm0 1.5H17v-1.5h-1.5v1.5Zm-15 0v-1.5H-1v1.5H.5ZM2 4.625a1 1 0 0 1 1-1v-3a4 4 0 0 0-4 4h3ZM2 5.75V4.625h-3V5.75h3ZM.5 7.25h.75v-3H.5v3Zm.75 0h13.5v-3H1.25v3Zm13.5 0h.75v-3h-.75v3ZM14 4.625V5.75h3V4.625h-3Zm-1-1a1 1 0 0 1 1 1h3a4 4 0 0 0-4-4v3Zm-10 0h10v-3H3v3ZM15.5 5.75h-.75v3h.75v-3Zm-.75 0H1.25v3h13.5v-3Zm-13.5 0H.5v3h.75v-3ZM2 13.375V7.25h-3v6.125h3Zm1 1a1 1 0 0 1-1-1h-3a4 4 0 0 0 4 4v-3Zm10 0H3v3h10v-3Zm1-1a1 1 0 0 1-1 1v3a4 4 0 0 0 4-4h-3Zm0-6.125v6.125h3V7.25h-3Z",
  fill: "#0517F8",
  mask: "url(#a)"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.5 1.5V4M10.5 1.5V4",
  stroke: "#0517F8",
  strokeWidth: 1.5,
  strokeLinecap: "round"
}));
export default SvgCalendarBlankFilled;
