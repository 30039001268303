import * as React from "react";
const SvgSettingsActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 6a6 6 0 0 1 6-6h20a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z",
  fill: "#fff",
  fillOpacity: 0.1
}), /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#sidebar-settings-active-a)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "m24.344 17.75-1.164-1.547c.008-.14 0-.289 0-.398l1.164-1.555a.608.608 0 0 0 .101-.54 8.968 8.968 0 0 0-.851-2.062.64.64 0 0 0-.453-.312l-1.914-.274-.29-.289-.273-1.914a.656.656 0 0 0-.305-.453 9.177 9.177 0 0 0-2.07-.851.61.61 0 0 0-.539.101l-1.547 1.156h-.406L14.25 7.656a.61.61 0 0 0-.54-.101 8.969 8.969 0 0 0-2.062.851.641.641 0 0 0-.312.453l-.274 1.914-.289.29-1.914.273a.64.64 0 0 0-.453.312 8.967 8.967 0 0 0-.851 2.063.61.61 0 0 0 .101.539l1.164 1.547v.398L7.656 17.75a.61.61 0 0 0-.101.539 8.97 8.97 0 0 0 .851 2.063.64.64 0 0 0 .453.312l1.914.273.29.29.273 1.913a.643.643 0 0 0 .312.454 8.97 8.97 0 0 0 2.063.851.602.602 0 0 0 .54-.102l1.546-1.156h.406l1.547 1.157a.608.608 0 0 0 .539.101 8.826 8.826 0 0 0 2.063-.851.64.64 0 0 0 .312-.454l.273-1.921c.094-.094.204-.196.282-.282l1.922-.273a.642.642 0 0 0 .453-.313c.368-.649.654-1.342.851-2.062a.61.61 0 0 0-.101-.54ZM16 19.437a3.438 3.438 0 1 1 0-6.875 3.438 3.438 0 0 1 0 6.875Z",
  fill: "#fff"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "sidebar-settings-active-a"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(6 6)",
  d: "M0 0h20v20H0z"
}))));
export default SvgSettingsActive;
