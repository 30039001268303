import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { compareVersions } from "compare-versions";
import { uncrunch } from "graphql-crunch";
import decode from "jwt-decode";
import { toast } from "react-toastify";
import { setRecoil } from "recoil-nexus";

import {
  APP,
  AUTH,
  CLIENT_AUTH_REQUEST_TYPE,
  CLIENT_AUTHENTICATION_METHOD,
  CRUNCH,
  JWT,
} from "./_config";

import { NAMESPACE } from "$/settings/app.json";
import { globalAppVersionAtom, globalErrorAtom } from "~/atoms/_global";

const fetcher = (...args) => {
  return window.fetch(...args);
};

const uncruncher = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    response.data = uncrunch(response.data);
    return response;
  }),
);

const { ROUTES: { OEM_LOGIN: LOGIN } = {} } = APP;

// replaced dyanmicaly
const date = "__DATE__";
const id = "__APP_ID__";

const version = import.meta.env.PACKAGE_VERSION;

const opts = {
  credentials: "same-origin",
  headers: {
    [`x-${NAMESPACE}-version`]: version,
    [`x-${NAMESPACE}-app`]: id,
    [`x-${NAMESPACE}-built-at`]: date,
    [AUTH.STRATEGIES.CLIENT.AUTH_HEADER]: CLIENT_AUTH_REQUEST_TYPE,
  },
  fetch: fetcher, // because of logrocket
};

const useLocalStorage = CLIENT_AUTHENTICATION_METHOD.LOCAL_STORAGE;

const apolloCache = new InMemoryCache();

const endpoint =
  import.meta.env.VITE_APP_GRAPHQL_ENDPOINT ?? APP.ENDPOINT.GRAPHQL;

const uri =
  CRUNCH && CRUNCH.USE ? `${endpoint}/?crunch=${CRUNCH.VERSION}` : endpoint;

const httpLink = new HttpLink({
  uri,
  ...opts,
});

const authMiddlewareLink = setContext(() => {
  const language = JSON.parse(localStorage.getItem("locale") || "{}");
  const headers = {
    headers: {
      "Accept-Language": language?.value || "en",
      [JWT.HEADER.TOKEN.NAME]:
        localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME) || null,
      [JWT.HEADER.REFRESH_TOKEN.NAME]:
        localStorage.getItem(JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME) || null, // eslint-disable-line
    },
  };

  if (headers.headers[JWT.HEADER.REFRESH_TOKEN.NAME]) {
    const currentTime = Date.now().valueOf() / 1000;
    const tokenExpiration = decode(
      headers.headers[JWT.HEADER.REFRESH_TOKEN.NAME],
    ).exp;
    if (currentTime > tokenExpiration) {
      if (useLocalStorage) {
        localStorage.removeItem(JWT.LOCAL_STORAGE.TOKEN.NAME);
        localStorage.removeItem(JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME);
      }
      if (!location.pathname.includes(LOGIN)) location.assign(LOGIN);
    }
  }
  return headers;
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const token = headers.get(JWT.HEADER.TOKEN.NAME);
      const refreshToken = headers.get(JWT.HEADER.REFRESH_TOKEN.NAME);
      const minimumAppVersionSupported = headers.get(`x-${NAMESPACE}-version`);
      const isAppOldVersion =
        compareVersions(minimumAppVersionSupported, version) === 1;
      setRecoil(globalAppVersionAtom, {
        minimumAppVersionSupported,
        showFlashMessage: isAppOldVersion,
      });

      if (token) {
        localStorage.setItem(JWT.LOCAL_STORAGE.TOKEN.NAME, token);
      }

      if (refreshToken) {
        localStorage.setItem(
          JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME,
          refreshToken,
        );
      }
    }
    return response;
  }),
);

const errorLink = onError(({ graphQLErrors = [], networkError = {} }) => {
  const redirect =
    (networkError && networkError.statusCode) ||
    (graphQLErrors && graphQLErrors.length > 0);
  if (redirect) {
    let { statusCode } = networkError;
    if (!statusCode) {
      const errors = graphQLErrors.filter(
        (e) => e.status === 403 || e.status === 401 || e.status === 422,
      );
      const { status = 200 } = errors[0] || {};
      statusCode = status;
    }
    if (statusCode === 401)
      if (
        !location.pathname.includes(LOGIN) &&
        !location.pathname.includes("forgot-password")
      )
        location.assign(
          `${LOGIN}?redirect=${encodeURIComponent(location.pathname)}`,
        );
    if (statusCode === 403) {
      console.error("Network Error: ", networkError);
      console.error("GraphQLErrors:", graphQLErrors);
      setRecoil(globalErrorAtom, { isErrorAlertOpen: true });
    }

    if (networkError?.statusCode === 422) {
      const transformedErrors = graphQLErrors?.reduce((obj, error) => {
        if (error?.status !== 422) return obj;
        return {
          ...obj,
          ...error.extensions.fields,
        };
      }, {});
      networkError.message = transformedErrors;
      networkError.graphQLErrors = graphQLErrors;
      if (!networkError.message || !Object.keys(transformedErrors || {}).length)
        toast.error("Oops, something went wrong!");
    }
    if (statusCode >= 500) {
      console.error("Network Error: ", networkError);
      console.error("GraphQLErrors:", graphQLErrors);
      setRecoil(globalErrorAtom, { isErrorAlertOpen: true });
    }
  }
});

let links = [
  errorLink,
  CRUNCH && CRUNCH.USE ? ApolloLink.concat(uncruncher, httpLink) : httpLink,
];

if (useLocalStorage) {
  links = [
    errorLink,
    afterwareLink,
    authMiddlewareLink,
    CRUNCH && CRUNCH.USE ? ApolloLink.concat(uncruncher, httpLink) : httpLink,
  ];
}

const link = ApolloLink.from(links);

const client = new ApolloClient({
  link,
  cache: apolloCache,
  connectToDevTools: true,
});

if (useLocalStorage) {
  let currentToken = window.localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME);

  window.onstorage = (event) => {
    if (event.key !== JWT.LOCAL_STORAGE.TOKEN.NAME) return;

    const newToken = window.localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME);

    if (currentToken && !newToken) {
      // someone in another tab logged out while I am logged in here
      currentToken = newToken;
      client.clearStore().then(() => location.replace(LOGIN));
    } else if (!currentToken && newToken) {
      // I am not logged in, but someone else just logged in in another tab
      currentToken = newToken;
      window.location.reload();
    } else if (currentToken && newToken && currentToken !== newToken) {
      // I am logged in and received a new token in local storage
      const currentTokenData = decode(currentToken);
      const newTokenData = decode(newToken);
      if (currentTokenData?.user?.id !== newTokenData?.user?.id) {
        // id(s) are different, thus someone in another tab logged in with different user while I was logged in with another user here
        // thus refresh the page to sync the user and view the new data
        window.location.reload();
      }
      // otherwise, I am logged in and received a new token in local storage
      // but it's the same user, so I don't need to do anything
      // it's standard behavior for refreshing tokens
    }
  };
}

// TODO: [rico] - handle the behavior when strategy is HTTP_ONLY

export default client;
