import * as React from "react";
const SvgNotificationActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 8 8",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("circle", {
  cx: 4,
  cy: 4,
  r: 3.5,
  fill: "#EE0064",
  stroke: "#2F415F"
}));
export default SvgNotificationActive;
