import {
  ASSIGN_OWN_OEM_PARTS_TO_COMPONENT_MUTATION,
  CREATE_OWN_OEM_COMPONENT_MUTATION,
  Component,
  DELETE_OEM_COMPONENT_MUTATION,
  GET_OWN_OEM_COMPONENT_BY_ID_QUERY,
  REMOVE_OWN_OEM_PART_FROM_COMPONENT_MUTATION,
  UPDATE_OWN_OEM_COMPONENT_MUTATION,
} from "#/src/api";
import { errorsHandler } from "#/src/transformers";
import { useMutation } from "@apollo/client";
import client from "../../apollo/_client";

export const useCreateOwnOemComponent = () => {
  const [create, { error = {}, loading }] = useMutation(
    CREATE_OWN_OEM_COMPONENT_MUTATION,
  );

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return create({
          variables: {
            input: {
              ...data,
            },
          },
          update: (cache, { data: { createOwnOemComponent: ref } }) => {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemComponents",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useDeleteComponent = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OEM_COMPONENT_MUTATION,
  );

  return {
    loading,
    error,
    deleteComponent: async (id) =>
      new Promise((resolve, reject) =>
        remove({
          variables: { id },
          skip: !id,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemComponents",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};

export const useUpdateComponent = () => {
  const [updateOwnOemComponent, { error = {}, loading }] = useMutation(
    UPDATE_OWN_OEM_COMPONENT_MUTATION,
  );

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return updateOwnOemComponent({
          variables: {
            input: {
              ...data,
            },
          },
          update(cache, { data: { updateOwnOemComponent: ref } }) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemComponents",
            });
            cache.modify({
              id: cache.identify({ __typename: "Component", ref: ref._id }),
              fields: {
                getOwnOemProductionLineById() {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: Component.fragments.ComponentData,
                    fragmentName: "ComponentData",
                  });

                  return newRef;
                },
              },
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const assignPartsToComponent = () => {
  const [add, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_PARTS_TO_COMPONENT_MUTATION,
  );

  return {
    addNewParts: async (input) =>
      add({
        variables: { input },
        skip: !input?.component,
        update(cache, { data: { assignOwnOemMultiplePartsToComponent: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Component", id: ref._id }),
            fields: {
              getOwnOemComponentById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Component.fragments.ComponentPartsData,
                  fragmentName: "ComponentPartsData",
                });
                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    loading,
    error,
  };
};

export const removeComponentPart = () => {
  const [remove, { loading, error }] = useMutation(
    REMOVE_OWN_OEM_PART_FROM_COMPONENT_MUTATION,
  );

  return {
    removePart: async (input) =>
      remove({
        variables: { input },
        skip: !input?.machineId || !input?.partId,
        update(cache, { data: { unassignOwnOemPartFromComponent: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Component", id: ref._id }),
            fields: {
              getOwnOemMachineById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Component.fragments.ComponentPartsData,
                  fragmentName: "ComponentPartsData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
    loading,
    error,
  };
};

export const updateComponentCache = (payload) => {
  const component = client.readQuery({
    query: GET_OWN_OEM_COMPONENT_BY_ID_QUERY,
    variables: { id: payload._id },
  });

  if (!component) return;

  const { getOwnOemComponentById: cachedComponent } = component;
  if (cachedComponent) {
    const cloned = { ...cachedComponent };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_OEM_COMPONENT_BY_ID_QUERY,
      variables: { id: payload._id },
      data: {
        getOwnOemComponentById: cloned,
      },
    });
  }
};
