import React from "react";
import { useIntl } from "react-intl";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "~/components/_toast";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { ForgotPasswordForm as Form } from "~/components/form";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_FAILED } from "~/constants";
import { forgotPassword } from "~/services";
import { useGetOemById } from "~/services/auth";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

const ForgotPassword = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { modifier } = useParams();
  const { mutation } = forgotPassword();

  const { error } = useGetOemById(modifier);

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  var renderPage = () => {
    return (
      <div className="auth">
        <div className="auth-main auth-main--full">
          <div className="o-column">
            <div className="auth-box">
              <div className="form-authHead form-authHead--center form-authHead--success">
                <Headline size={HEADLINE_SIZES.SMALL} className="mb-sm">
                  {messages?.login?.forgotPassword.title}
                </Headline>
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="opacity-70">
                  {messages?.login?.forgotPassword.message}
                </BodyText>
              </div>
              <Form
                callback={async (values) => {
                  const { email: userEmail } = values;
                  try {
                    registerMixpanelEvent(FORGOT_PASSWORD, {
                      oem: window.location.pathname
                        .split("/")
                        .slice(-1)
                        .join("")
                        .split("?")[0],
                      userEmail,
                    });
                    await mutation(values);
                    history.replace(
                      `/forgot-password-success${
                        modifier ? "/" + modifier : ""
                      }?email=${encodeURIComponent(values?.email)}`,
                    );
                  } catch (e) {
                    toast(
                      <Toast
                        message={e?.message || `${messages?.login?.wrongCreds}`}
                        type="error"
                      />,
                      { closeButton: false },
                    );
                    registerMixpanelEvent(FORGOT_PASSWORD_FAILED, {
                      oem: window.location.pathname
                        .split("/")
                        .slice(-1)
                        .join("")
                        .split("?")[0],
                      userEmail,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderPage();
};

export default ForgotPassword;
