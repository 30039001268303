import * as React from "react";
const SvgInventoryFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3308)"
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip1_66_3308)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "m17.18 5.21-6.563-3.702a1.242 1.242 0 0 0-1.234 0L2.82 5.21a1.266 1.266 0 0 0-.632 1.094v7.39a1.265 1.265 0 0 0 .632 1.094l6.563 3.703a1.242 1.242 0 0 0 1.234 0l6.563-3.703a1.266 1.266 0 0 0 .633-1.094v-7.39a1.266 1.266 0 0 0-.633-1.094ZM10 12.814a2.812 2.812 0 1 1 0-5.625 2.812 2.812 0 0 1 0 5.625Z",
  fill: "#0517F8"
}))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3308"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
})), /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip1_66_3308"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgInventoryFilled;
