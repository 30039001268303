import React, { Suspense } from "react";
import { useAuthQuery } from "../../services";
import Loading from "./_loading";

const ConnectionResult = ({ children }) => {
  useAuthQuery();
  return children;
};

const Authenticate = (props) => (
  <Suspense fallback={<Loading />}>
    <ConnectionResult {...props} />
  </Suspense>
);

export default Authenticate;
