import * as React from "react";
const SvgProductionLinesActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  y: 10,
  width: 14.142,
  height: 14.142,
  rx: 2.5,
  transform: "rotate(-45 0 10)",
  fill: "#0517F8"
}), /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_356_3110)",
  fill: "#fff"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M9.718 4.323 8.026 6.005a.467.467 0 0 0 0 .663l1.691 1.68a.473.473 0 0 0 .667 0l1.692-1.682a.467.467 0 0 0 0-.663l-1.691-1.68a.473.473 0 0 0-.667 0ZM13.49 8.073l-1.692 1.682a.467.467 0 0 0 0 .663l1.691 1.68a.473.473 0 0 0 .667 0l1.692-1.682a.467.467 0 0 0 0-.663l-1.691-1.68a.473.473 0 0 0-.667 0ZM5.947 8.067 4.255 9.749a.467.467 0 0 0 0 .663l1.69 1.68a.474.474 0 0 0 .667 0l1.692-1.681a.467.467 0 0 0 0-.663l-1.69-1.681a.473.473 0 0 0-.667 0ZM9.718 11.817l-1.692 1.682a.467.467 0 0 0 0 .663l1.691 1.68a.473.473 0 0 0 .667 0l1.692-1.681a.467.467 0 0 0 0-.663l-1.691-1.681a.473.473 0 0 0-.667 0Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_356_3110"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  transform: "translate(2.51 2.583)",
  d: "M0 0h15.087v15H0z"
}))));
export default SvgProductionLinesActive;
