import * as React from "react";
const SvgEmlDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#EE0064"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.5 20.32a.5.5 0 0 1-.5-.5V16.5a.5.5 0 0 1 .5-.5h1.963a.297.297 0 0 1 0 .594H10.63V17.8h1.473a.297.297 0 0 1 0 .594H10.63v1.332h1.833a.297.297 0 0 1 0 .594H10.5ZM13.777 20.32a.297.297 0 0 1-.296-.297V16.35a.35.35 0 0 1 .665-.151l1.465 3.053 1.464-3.053a.35.35 0 0 1 .665.151v3.676a.294.294 0 1 1-.588 0v-2.664l-1.336 2.828a.227.227 0 0 1-.41 0l-1.332-2.828v2.661a.297.297 0 0 1-.296.297ZM19.138 20.32a.5.5 0 0 1-.5-.5v-3.505a.315.315 0 1 1 .63 0v3.411h1.66a.297.297 0 0 1 0 .594h-1.79Z",
  fill: "#fff"
}));
export default SvgEmlDocument;
