import {
  ASSIGN_OWN_OEM_MULTIPLE_MACHINES_TO_PRODUCTION_LINE_MUTATION,
  CREATE__OWN_OEM_PRODUCTION_LINE_MUTATION,
  DELETE_OEM_PRODUCTION_LINE_MUTATION,
  ProductionLine,
  UNASSIGN_OWN_OEM_MACHINE_FROM_PRODUCTION_LINE_MUTATION,
  UPDATE_OEM_PRODUCTION_LINE_MUTATION,
  GET_OWN_OEM_PRODUCTION_LINE_BY_ID_QUERY,
} from "#/src/api";
import { errorsHandler } from "#/src/transformers";
import { useMutation } from "@apollo/client";
import client from "../../apollo/_client";

export const useCreateOwnOemProductionLine = () => {
  const [create, { error = {}, loading }] = useMutation(
    CREATE__OWN_OEM_PRODUCTION_LINE_MUTATION,
  );

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return create({
          variables: {
            input: {
              ...data,
            },
          },
          update: (
            cache,
            { data: { createOwnOemProductionLine: productionLine } },
          ) => {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemProductionLines",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useDeleteProductionLine = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OEM_PRODUCTION_LINE_MUTATION,
  );

  return {
    loading,
    error,
    deleteProductionLine: async (id) =>
      new Promise((resolve, reject) =>
        remove({
          variables: { id },
          skip: !id,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemProductionLines",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};

export const useUpdateProductionLine = () => {
  const [updateOwnOemProductionLine, { error = {}, loading }] = useMutation(
    UPDATE_OEM_PRODUCTION_LINE_MUTATION,
  );

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return updateOwnOemProductionLine({
          variables: {
            input: {
              ...data,
            },
          },
          update(cache, { data: { updateOwnOemProductionLine: ref } }) {
            cache.modify({
              id: cache.identify({
                __typename: "ProductionLine",
                ref: ref._id,
              }),
              fields: {
                getOwnOemProductionLineById() {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: ProductionLine.fragments.ProductionLineData,
                    fragmentName: "ProductionLineData",
                  });

                  return newRef;
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const assignMachinesToProductionLine = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_MULTIPLE_MACHINES_TO_PRODUCTION_LINE_MUTATION,
  );

  return {
    error,
    assigningMachines: loading,
    assignMachines: async (input) =>
      assign({
        variables: { input },
        skip: !input?.productionLine,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });
          cache.gc();
        },
      }),
  };
};

export const unassignMachineFromProductionLine = () => {
  const [remove, { loading, error }] = useMutation(
    UNASSIGN_OWN_OEM_MACHINE_FROM_PRODUCTION_LINE_MUTATION,
  );

  return {
    loading,
    error,
    unassignMachine: async (input) =>
      remove({
        variables: { input },
        skip: !input?.productionLine || !input?.machine,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });
          cache.gc();
        },
      }),
  };
};

export const updateProductionLineCache = (payload) => {
  const productionLine = client.readQuery({
    query: GET_OWN_OEM_PRODUCTION_LINE_BY_ID_QUERY,
    variables: { id: payload._id },
  });

  if (!productionLine) return;

  const { getOwnOemProductionLineById: cachedProductionLine } = productionLine;
  if (cachedProductionLine) {
    const cloned = { ...cachedProductionLine };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_OEM_PRODUCTION_LINE_BY_ID_QUERY,
      variables: { id: payload._id },
      data: {
        getOwnOemProductionLineById: cloned,
      },
    });
  }
};
