import * as React from "react";
const SvgDocuments = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3124)",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M2.5 6.25V4.375a.625.625 0 0 1 .625-.625h4.117a.617.617 0 0 1 .438.18L10 6.25M8.797 16.25H3.078a.58.58 0 0 1-.578-.578V6.25m15 1.875v-1.25a.625.625 0 0 0-.625-.625H10M14.688 15.508l2.32 1.367-.633-2.547 2.063-1.703-2.711-.21L14.687 10l-1.039 2.414-2.71.211L13 14.328l-.633 2.547 2.32-1.367Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3124"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgDocuments;
