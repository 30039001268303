import * as React from "react";
const Svg3DActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 6a6 6 0 0 1 6-6h20a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z",
  fill: "#fff",
  fillOpacity: 0.1
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M24 19.818v-7.636c0-.784-.407-1.496-1.042-1.82l-6.222-3.183a1.6 1.6 0 0 0-1.472 0l-6.222 3.182C8.407 10.686 8 11.398 8 12.181v7.637c0 .784.407 1.496 1.042 1.82l6.222 3.183c.468.239 1.004.239 1.472 0l6.222-3.182c.635-.325 1.042-1.037 1.042-1.82Zm-10.958-6.92h.004c1.06 0 1.92.86 1.92 1.92 0 .454-.17.867-.449 1.182.279.314.448.728.448 1.181 0 1.06-.86 1.92-1.92 1.92h-.003a1.9 1.9 0 0 1-1.823-1.403.6.6 0 0 1 1.158-.314.7.7 0 0 0 .67.517.72.72 0 0 0 .718-.72.582.582 0 0 0-.581-.581.6.6 0 0 1 0-1.2c.32 0 .581-.26.581-.582a.72.72 0 0 0-.718-.72.7.7 0 0 0-.67.518.6.6 0 1 1-1.158-.314 1.9 1.9 0 0 1 1.823-1.403Zm3.224.6a.6.6 0 0 1 .6-.6h1.41a2.526 2.526 0 0 1 2.526 2.527v1.151a2.527 2.527 0 0 1-2.526 2.525v-.6.6h-1.41a.6.6 0 0 1-.6-.6v-5.002Zm2.01 4.403c.732 0 1.325-.593 1.326-1.325v-1.151c0-.732-.594-1.326-1.326-1.326h-.81V17.9h.81Z",
  fill: "#fff"
}));
export default Svg3DActive;
