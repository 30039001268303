import { useQuery } from "@apollo/client";
import { LIST_OWN_OEM_TIME_TRACKERS_QUERY } from "../../api/_queries";

import TYPES from "$/settings/enums/timeTracker/_type.json";
import FIELD_TYPES from "$/settings/enums/timeTracker/_field-type.json";
import { getEnums } from "~/utils";

const Types = getEnums(TYPES, "reference");
const FieldTypes = getEnums(FIELD_TYPES, "reference");

export const getTimeTrackerAllTags = () => {
  const {
    data: { listOwnOemTimeTrackers = [] } = {},
    loading,
    error,
  } = useQuery(LIST_OWN_OEM_TIME_TRACKERS_QUERY, {
    variables: {
      type: Types.tickets,
      fieldType: FieldTypes.tag,
    },
  });

  return { allTags: listOwnOemTimeTrackers, fetchingTags: loading, error };
};
