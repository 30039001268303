import {
  CalendarBlank,
  CaretCircleDown,
  HashStraight,
  ListBullets,
  ListChecks,
  TextAlignLeft,
  User,
} from "phosphor-react";
import React from "react";

import { COLOR } from "~/constants/colors";
import {
  ImageFieldTypeIcon,
  InventoryIcon,
  ProcedureCheckboxFieldIcon,
  TableFieldTypeIcon,
} from "~/icons/icons";

export const PROCEDURE_DESC_LENGTH = 270;
export const PROCEDURE_CHECKLIST_OPTION_LENGTH = 100;
export const PROCEDURE_SINGLE_SELECT_OPTION_LENGTH = 80;
export const PROCEDURE_TABLE_MAX_COL = 16;
export const PROCEDURE_TABLE_CELL_MIN_WIDTH = 150;
export const PROCEDURE_SIGNATURE_NAME_MAX_LENGTH = 30;
export const PROCEDURE_ATTACHING_TOAST_ID =
  "procedure-template-attaching-toast";
export const PROCEDURE_TEMPLATE_DUPLICATING_TOAST_ID =
  "procedure-instance-duplicating-toast";
export const PROCEDURE_PDF_DOWNLOADING_TOAST_ID =
  "procedure-instance-downloading-toast";
export const PROCEDURE_FINALIZING_TOAST_ID =
  "procedure-instance-finalizing-toast";
export const PROCEDURE_UPDATE_IDENTIFIER = "saveOwnOemProcedureTemplate";
export const PROCEDURE_UPDATING_TOAST_ID = "attached-procedure-updating-toast";
export const PROCEDURE_FIELD_DESCRIPTION_MAX_LENGTH = 300;
export const PROCEDURE_FIELD_LABEL_MAX_LENGTH = 150;
export const PROCEDURE_FIELD_DESCRIPTION_MAX_HEIGHT = 150;

export const PROCEDURE_FIELD_TYPE = {
  TEXT_AREA_FIELD: "TEXT_AREA_FIELD",
  NUMBER_FIELD: "NUMBER_FIELD",
  DATE_FIELD: "DATE_FIELD",
  CHECKLIST_FIELD: "CHECKLIST_FIELD",
  CHECKBOX: "CHECKBOX_FIELD",
  IMAGE_UPLOADER_FIELD: "IMAGE_UPLOADER_FIELD",
  PARTS_FIELD: "PARTS_FIELD",
  DOCUMENT_UPLOADER_FIELD: "DOCUMENT_UPLOADER_FIELD",
  AMOUNT_FIELD: "AMOUNT_FIELD",
  SINGLE_SELECT: "SINGLE_SELECT_FIELD",
  TABLE_FIELD: "TABLE_FIELD",
  MEMBER_FIELD: "MEMBER_FIELD",
  LIST_FIELD: "LIST_FIELD",
  DROPDOWN_FIELD: "DROPDOWN_FIELD",
};

export const PROCEDURE_NODE_PROPERTY = {
  NAME: "name",
  TYPE: "type",
  ATTACHMENTS: "attachments",
  DESCRIPTION: "description",
  SHOW_DESCRIPTION: "showDescription",
  IS_REQUIRED: "isRequired",
  OPTIONS: "options",
  TABLE_OPTION: "tableOption",
};

export const PROCEDURE_NODE_TYPE = {
  HEADING: "HEADING",
  SECTION: "SECTION",
  FIELD: "FIELD",
};

export const PROCEDURE_FIELD_TYPE_ITEMS = [
  {
    value: PROCEDURE_FIELD_TYPE.TEXT_AREA_FIELD,
    label: "Text",
    itemId: PROCEDURE_FIELD_TYPE.TEXT_AREA_FIELD,
    icon: <TextAlignLeft className="u-text-color-green-v2-100 " size={16} />,
  },
  {
    value: PROCEDURE_FIELD_TYPE.NUMBER_FIELD,
    label: "Number",
    itemId: PROCEDURE_FIELD_TYPE.NUMBER_FIELD,
    icon: <HashStraight className="u-text-color-orange-v2-100 " size={16} />,
  },
  {
    value: PROCEDURE_FIELD_TYPE.DATE_FIELD,
    label: "Date",
    itemId: PROCEDURE_FIELD_TYPE.DATE_FIELD,
    icon: <CalendarBlank className="u-text-color-blue-v2-100 " size={16} />,
  },
  {
    value: PROCEDURE_FIELD_TYPE.IMAGE_UPLOADER_FIELD,
    label: "Image",
    itemId: PROCEDURE_FIELD_TYPE.IMAGE_UPLOADER_FIELD,
    icon: <ImageFieldTypeIcon strokeColor={COLOR.$mint_blue_v2_100} />,
  },
  {
    value: PROCEDURE_FIELD_TYPE.TABLE_FIELD,
    label: "Table",
    itemId: PROCEDURE_FIELD_TYPE.TABLE_FIELD,
    icon: <TableFieldTypeIcon color={COLOR.$mint_blue_v2_100} size={16} />,
    hasBorderBottom: true,
  },
  {
    value: PROCEDURE_FIELD_TYPE.CHECKLIST_FIELD,
    label: "Checklist",
    itemId: PROCEDURE_FIELD_TYPE.CHECKLIST_FIELD,
    icon: <ListChecks className="u-text-color-red-v2-50 " size={16} />,
  },
  {
    value: PROCEDURE_FIELD_TYPE.CHECKBOX,
    label: "Checkbox",
    itemId: PROCEDURE_FIELD_TYPE.CHECKBOX,
    icon: (
      <ProcedureCheckboxFieldIcon
        fillColor={COLOR.$yellow_v2_100}
        strokeColor={COLOR.$yellow_v2_100}
      />
    ),
  },
  {
    value: PROCEDURE_FIELD_TYPE.SINGLE_SELECT,
    label: "List",
    itemId: PROCEDURE_FIELD_TYPE.SINGLE_SELECT,
    icon: <ListBullets size={16} className="text-yellow-100" />,
  },
  {
    value: PROCEDURE_FIELD_TYPE.DROPDOWN_FIELD,
    label: "Dropdown",
    itemId: PROCEDURE_FIELD_TYPE.DROPDOWN_FIELD,
    icon: <CaretCircleDown size={16} className="text-blue-100" />,
    hasBorderBottom: true,
  },
  {
    value: PROCEDURE_FIELD_TYPE.PARTS_FIELD,
    label: "Parts",
    itemId: PROCEDURE_FIELD_TYPE.PARTS_FIELD,
    icon: <InventoryIcon strokeColor={COLOR.$red_v2_100} />,
  },
  {
    value: PROCEDURE_FIELD_TYPE.MEMBER_FIELD,
    label: "Member",
    itemId: PROCEDURE_FIELD_TYPE.MEMBER_FIELD,
    icon: <User className="u-text-color-orange-v2-100 " size={16} />,
  },
];
