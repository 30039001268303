import * as React from "react";
const SvgTxtDocument = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7 4h12.745l6.755 6.729V26.5A1.5 1.5 0 0 1 25 28H7a1.5 1.5 0 0 1-1.5-1.5v-21A1.5 1.5 0 0 1 7 4Z",
  fill: "#172B4D"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M26.478 10.75H21.25a1.5 1.5 0 0 1-1.5-1.5V4.015l6.728 6.735Z",
  fill: "#fff",
  fillOpacity: 0.7
}), /* @__PURE__ */ React.createElement("rect", {
  x: 17.141,
  y: 16,
  width: 0.83,
  height: 5.101,
  rx: 0.415,
  transform: "rotate(38 17.14 16)",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 14.141,
  y: 16.522,
  width: 0.83,
  height: 5.063,
  rx: 0.415,
  transform: "rotate(-39 14.14 16.522)",
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.624 16.863c-.251 0-.374-.219-.374-.431 0-.22.128-.432.374-.432h1.431a.47.47 0 0 1 .042 0h1.43c.247 0 .375.212.375.432 0 .212-.123.431-.374.431H12.5v3.141c0 .314-.179.488-.419.488h-.01c-.24 0-.42-.174-.42-.488v-3.14h-1.027ZM18.374 16.863c-.251 0-.374-.219-.374-.431 0-.22.128-.432.374-.432h1.431a.47.47 0 0 1 .042 0h1.43c.247 0 .375.212.375.432 0 .212-.123.431-.374.431H20.25v3.141c0 .314-.179.488-.419.488h-.01c-.24 0-.42-.174-.42-.488v-3.14h-1.027Z",
  fill: "#fff"
}));
export default SvgTxtDocument;
