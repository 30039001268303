import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const initialState = { x: 0, y: 0, index: 0, isVirtualList: false };
const SUFFIX = "VIEW_PERSIST";

const useScrollPersist = (page) => {
  const location = useLocation();
  const [scope, _setScope] = useState(`${page || location.pathname}-${SUFFIX}`);
  const [scroll, setScroll] = useState(initialState);

  /*
   * Get scope name
   * return string
   */
  const getScope = (sc) => {
    return `${sc}-${SUFFIX}`;
  };

  /*
   * Set scope
   * return void
   */
  const setScope = (sc) => {
    _setScope(`${sc}-${SUFFIX}`);
  };

  /*
   * Get scroll pos from session store of given scope
   * return object
   */
  const _getScrollPos = () => {
    return JSON.parse(sessionStorage.getItem(scope)) || initialState;
  };

  /*
   * Get scope data from session store of given scope
   * @params sc: string | null
   * return any
   */
  const getScopeData = (sc) => {
    const _scope = sc ? `${sc}-${SUFFIX}` : scope;
    return JSON.parse(sessionStorage.getItem(_scope));
  };

  /*
   * Set scroll pos into session store of given scope
   * @params pos: any
   * return void
   */
  const setScopeData = (pos) => {
    const position = typeof pos === "string" ? pos : JSON.stringify(pos);

    sessionStorage.setItem(scope, position);
  };

  /*
   * Set scroll row index of VList into session store of given scope
   * @params index: number
   * return void
   */
  const setVListPos = (index) => {
    const position = JSON.stringify({ index, x: 0, y: 0, isVirtualList: true });

    sessionStorage.setItem(scope, position);
  };

  const clearScopeScroll = (page) => {
    const sc = page ? `${page}-${SUFFIX}` : scope;
    sessionStorage.removeItem(sc);
  };

  /*
   * Remove all scroll pos session data
   * return void
   */
  const clearAll = () => {
    for (const key in sessionStorage) {
      if (key.includes(SUFFIX)) {
        sessionStorage.removeItem(key);
      }
    }
  };

  useEffect(() => {
    const scrollPos = _getScrollPos();

    if (!scrollPos.isVirtualList) {
      window.scrollTo(scrollPos.x, scrollPos.y);
    }

    setScroll(scrollPos);
  }, []);

  return {
    scroll,
    getScopeData,
    setScopeData,
    setVListPos,
    clearAll,
    clearScopeScroll,
    getScope,
    setScope,
  };
};

export default useScrollPersist;
