import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/it";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/it";
import "@formatjs/intl-relativetimeformat/polyfill";
import React from "react";
import { IntlProvider } from "react-intl";
import { atom, useRecoilValue } from "recoil";
import { getLocaleFromStorage, saveLocaleToStorage } from "../utils";
import languages from "./index";
import LOCALES from "./locales";

function loadLanguageFromLocalStorage() {
  let lang = getLocaleFromStorage();

  if (!lang) {
    saveLocaleToStorage(LOCALES[0]);
    lang = LOCALES[0];
  }

  return lang;
}

export const languageAtom = atom({
  key: "languageAtom",
  default: {
    lang: loadLanguageFromLocalStorage(),
  },
});

const LanguageProvider = ({ children }) => {
  const { lang } = useRecoilValue(languageAtom);

  return (
    <IntlProvider
      locale={lang.locale}
      defaultLocale={lang.locale}
      messages={languages[lang.locale]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
