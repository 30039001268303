import * as React from "react";
const SvgFolder = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3116)",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M2.5 16.25V5a.625.625 0 0 1 .625-.625h4.164c.135 0 .267.044.375.125l2.172 1.625c.108.08.24.124.375.125h5.414a.625.625 0 0 1 .625.625V8.75"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m2.5 16.25 2.36-7.07a.625.625 0 0 1 .593-.43h12.43a.625.625 0 0 1 .594.82l-2.227 6.68H2.5Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3116"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgFolder;
