import * as React from "react";
const SvgProceduresFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "m12.362 3.948 2.357 2.357c.19.19.298.45.298.718v9.31c0 .562-.455 1.017-1.017 1.017H5.667a1.016 1.016 0 0 1-1.017-1.017V4.667c0-.562.455-1.017 1.017-1.017h5.976c.27 0 .528.107.719.298Z",
  fill: "#0517F8",
  stroke: "#0517F8",
  strokeWidth: 1.3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.667 7.167h-3.334a.834.834 0 0 1-.833-.834V3M6.5 12.167h5M6.5 14.667h3.608",
  stroke: "#fff",
  strokeWidth: 1.3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgProceduresFilled;
