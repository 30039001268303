import { bottom, left, right, top } from "@popperjs/core";
import { DOMAttributes, HTMLAttributes } from "react";
import { OverlayProps } from "react-overlays/Overlay";

type PropsType = {
  arrowClasses?: HTMLAttributes<HTMLDivElement>["className"];
  children: DOMAttributes<HTMLDivElement>["children"];
  className: HTMLAttributes<HTMLDivElement>["className"];
};

const ARROW_CLASS_POSITON_MAP = {
  [bottom]: "-top-1",
  [left]: "-right-1",
  [right]: "-left-1",
  [top]: "-bottom-1",
};

const OverlayWrapper =
  ({ arrowClasses, children, className }: PropsType) =>
  ({
    arrowProps: {
      className: _arrowClasses,
      style: arrowStyle,
      ...restArrowProps
    },
    placement,
    props: { className: containerClasses, ...restContainerProps },
  }: Parameters<OverlayProps["children"]>[0]) => (
    <div
      className={["absolute", className, containerClasses]
        .filter(Boolean)
        .join(" ")}
      {...restContainerProps}
    >
      <div
        className={[
          "absolute before:absolute before:content-empty before:h-2 before:left-0 before:rotate-45 before:top-0 before:w-2 h-2 w-2",
          _arrowClasses,
          arrowClasses,
          ARROW_CLASS_POSITON_MAP[placement],
        ]
          .filter(Boolean)
          .join(" ")}
        style={arrowStyle}
        {...restArrowProps}
      />
      {children}
    </div>
  );

export default OverlayWrapper;
