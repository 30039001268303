import { WarningCircle } from "phosphor-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

const ForgotPasswordForm = ({ callback = () => {} }) => {
  const { messages: msg } = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(false);
    callback(data);
  };

  const onError = () => {
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <div className="input-v2-wrapper auth-formGroup">
        <label htmlFor="email" className={"input-v2-label c-label"}>
          {msg?.login?.forgotPassword.email.label}
        </label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder={msg?.login?.forgotPassword.email.placeholder}
          {...register("email", { required: true })}
          className={`input-v2 auth-input ${
            errors?.email ? "input-v2-error" : ""
          }`}
        />

        {errors?.email && (
          <p className="input-v2-error-label">
            <WarningCircle className="input-v2-error-icon" size={16} />
            {errors?.email &&
              `${msg?.login?.forgotPassword.email.invalidEmail}`}
          </p>
        )}
      </div>
      <div className="form-group auth-submit forget-password-btn">
        <button
          className={`c-button c-button--primary primary-icon-btn-v2 ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          onClick={() => {
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading
            ? `${msg?.login?.forgotPassword.resetLoading}`
            : `${msg?.login?.forgotPassword.reset}`}
        </button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
