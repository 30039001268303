import * as React from "react";
const SvgWorkManagementActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 36 36",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 6a6 6 0 0 1 6-6h24a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z",
  fill: "#fff",
  fillOpacity: 0.1
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.333 25.5h-1.666a4.167 4.167 0 0 1-4.167-4.167v-6.666a4.167 4.167 0 0 1 4.167-4.167h6.666a4.167 4.167 0 0 1 4.167 4.167v2.5",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "m13.833 15.087.692.621 1.392-1.25M18.833 15.5h3.334M13.833 19.254l.692.621 1.392-1.25",
  stroke: "#fff",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M25.5 20.058h-2.917l-.585-.86a.836.836 0 0 0-.689-.365h-1.642a.834.834 0 0 0-.834.834v5c0 .46.373.833.834.833H25.5c.46 0 .833-.373.833-.833v-3.775a.834.834 0 0 0-.833-.834Z",
  fill: "#fff"
}));
export default SvgWorkManagementActive;
