import ContactSupportIcon from "@shared/svg/contact-support.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { ROUTES_TO_FEATURE_FLAG_MAP } from "~/components/common/FeatureAccess/constants";
import { useAuth } from "~/components/general";
import { UserType } from "~/types/user";

const FeatureAccess = ({ children }: { children: React.ReactChildren }) => {
  const { user } = useAuth() as { user: UserType };
  const { messages } = useIntl();
  const history = useHistory();
  const [state, setState] = useState<{
    featureEnabled: boolean;
    featureName: string;
  }>({ featureEnabled: true, featureName: "" });

  useEffect(() => {
    Object.entries(ROUTES_TO_FEATURE_FLAG_MAP).forEach(
      ([route, featureSpec]) => {
        if (history.location.pathname.startsWith(route)) {
          setState({
            featureEnabled: user.oem.paidFeatures.includes(featureSpec.flag),
            // @ts-ignore
            featureName: featureSpec.getFeatureDeniedMessage(messages),
          });
        }
      },
    );
  }, [user, history.location.pathname, messages]);

  return (
    <>
      <AlertBox
        overlay
        isOpen={!state.featureEnabled}
        acceptButtonText={messages?.common?.["okay"]}
        image={<ContactSupportIcon width="130" height="130" />}
        alertContent={
          <Headline size={HEADLINE_SIZES.X_SMALL}>
            {state.featureName}{" "}
            <a href="mailto:support@makula.io" className="text-brand">
              support@makula.io
            </a>
          </Headline>
        }
        onAccept={() => {
          return history.replace("/app/assets");
        }}
      />
      {children}
    </>
  );
};

export default FeatureAccess;
