import PropTypes from "prop-types";
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Check if the error is related to chunk loading
    const isChunkLoadError =
      error.message.includes(
        "'text/html' is not a valid JavaScript MIME type",
      ) ||
      error.message.includes("error loading dynamically imported module") ||
      error.message.includes("Failed to fetch dynamically imported module") ||
      error.message.includes("Importing a module script failed") ||
      error?.name === "ChunkLoadError" ||
      error?.message.includes("Loading chunk");

    if (isChunkLoadError) {
      window.location.reload(true);
    } else {
      this.setState({ hasError: true, error });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <section className="errorPage">
          <hgroup className="errorPage-hgroup">
            <h1>Oops!</h1>
            <h2>Something went wrong!</h2>
          </hgroup>
          <div className="errorPage-text">
            <p>
              Try to refresh this page or load{" "}
              <a href="/" onClick={() => window.location("/")}>
                Home
              </a>
            </p>
          </div>
        </section>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
