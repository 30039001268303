import * as React from "react";
const SvgHelp = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32",
  fill: "none",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M8.07059 8.07062C8.26811 7.21932 9.04347 6.62907 9.91663 6.66531C10.8939 6.61118 11.7316 7.35585 11.7924 8.33267C11.7924 9.58645 10 10 10 10.8337",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  transform: "translate(6 6)"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 2.49687,
  y: 2.49689,
  width: 15.0062,
  height: 15.0062,
  rx: 5,
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  transform: "translate(6 6)"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.1042 13.1263C10.1042 13.1839 10.0576 13.2305 10 13.2305C9.94245 13.2305 9.89579 13.1839 9.89579 13.1263C9.89579 13.0688 9.94245 13.0221 10 13.0221",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  transform: "translate(6 6)"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10 13.0221C10.0576 13.0221 10.1042 13.0687 10.1042 13.1263",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  transform: "translate(6 6)"
}));
export default SvgHelp;
