import React from "react";
import Modal from "react-modal";
import { atom, useRecoilState } from "recoil";

export const modalAtom = atom({
  key: "modalOptions",
  default: {
    disabledActions: false,
    title: "",
    lead: "",
    isOpen: false,
    cancel: "Cancel",
    confirm: "Delete",
    onConfirm: () => {},
    onCancel: () => {},
  },
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ConfirmationModal = () => {
  const [modalOptions, setModalOptions] = useRecoilState(modalAtom);

  const cancel = () => {
    setModalOptions({
      ...modalOptions,
      isOpen: false,
    });
    modalOptions.onCancel();
  };

  const confirm = () => {
    modalOptions.onConfirm();
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalOptions.isOpen}
      onRequestClose={cancel}
      style={customStyles}
    >
      <div className="modal-header">
        <h2 className="modal-headerTitle">{modalOptions.title}</h2>
        <button className="modal-headerClose" onClick={cancel}></button>
      </div>
      <div className="modal-content">
        <p>{modalOptions.lead}</p>
      </div>
      {modalOptions.disabledActions === false && (
        <div className="c-buttons">
          <button className="c-button c-button--primaryEmpty" onClick={cancel}>
            {modalOptions.cancel}
          </button>
          <button
            className="c-button c-button--danger c-button--primary"
            onClick={confirm}
          >
            {modalOptions.confirm}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
