import * as React from "react";
const SvgMachinesFilled = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_66_3166)"
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip1_66_3166)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M6.875 5.883a1.258 1.258 0 0 1 0-1.766l2.242-2.242a1.242 1.242 0 0 1 1.766 0l2.242 2.242a1.258 1.258 0 0 1 0 1.766l-2.242 2.242a1.242 1.242 0 0 1-1.766 0L6.875 5.883Zm11.25 3.234-2.242-2.242a1.242 1.242 0 0 0-1.766 0l-2.242 2.242a1.258 1.258 0 0 0 0 1.766l2.242 2.242a1.241 1.241 0 0 0 1.766 0l2.242-2.242a1.258 1.258 0 0 0 0-1.766Zm-10 0L5.883 6.875a1.242 1.242 0 0 0-1.766 0L1.875 9.117a1.258 1.258 0 0 0 0 1.766l2.242 2.242a1.242 1.242 0 0 0 1.766 0l2.242-2.242a1.258 1.258 0 0 0 0-1.766Zm2.758 2.758a1.241 1.241 0 0 0-1.766 0l-2.242 2.242a1.258 1.258 0 0 0 0 1.766l2.242 2.242a1.242 1.242 0 0 0 1.766 0l2.242-2.242a1.258 1.258 0 0 0 0-1.766l-2.242-2.242Z",
  fill: "#0517F8"
}))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_66_3166"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
})), /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip1_66_3166"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h20v20H0z"
}))));
export default SvgMachinesFilled;
