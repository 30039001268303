import * as React from "react";
const SvgCalendarBlank = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M1.75 6.5h12.5",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "square"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 1.1,
  y: 2.725,
  width: 13.8,
  height: 12.55,
  rx: 1.9,
  stroke: "#748094",
  strokeWidth: 1.2
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.5 1.5V4M10.5 1.5V4",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round"
}));
export default SvgCalendarBlank;
