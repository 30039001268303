import { atom } from "recoil";
import { MD_MAX_WIDTH } from "../constants";

export const chatAtom = atom({
  key: "chatOptions",
  default: {
    pubnub: {},
  },
});

export const chatToggleAtom = atom({
  key: "chatToggle",
  default: {
    isOpen: window.innerWidth > MD_MAX_WIDTH,
  },
});

export const chatImagesAtom = atom({
  key: "chatImages",
  default: {
    images: [],
  },
});

export const imagePreviewAtom = atom({
  key: "imagePreviewOptions",
  default: {
    isOpen: false,
    openedImage: "",
  },
});

export const unreadMessagesAtom = atom({
  key: "unreadMessagesOptions",
  default: {
    channels: [],
    channelTimetokens: [],
    messageRead: false,
  },
});

export const unreadChannelMessagesAtom = atom({
  key: "unreadChannelMessages",
  default: {
    channels: {},
  },
});

export const internalNotesAtom = atom({
  key: "internalNotes",
  default: {
    messages: [],
    sendMessage: null,
    sendFile: null,
  },
});

export const allMembershipChannelsAtom = atom({
  key: "allMembershipChannels",
  default: {},
});
