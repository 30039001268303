import { mapColorsFromPalette } from "./_colors";

export const getSortedCustomFields = (customFields) => {
  if (!customFields?.length) return [];

  let sortedCustomFields = customFields;

  const additionalFields = customFields?.filter(
    (field) => field.isAdditionalField,
  );

  if (additionalFields.length) {
    sortedCustomFields = sortedCustomFields.filter(
      (field) => !field.isAdditionalField,
    );
  }
  if (
    sortedCustomFields[0]?.order !== null &&
    sortedCustomFields[0]?.order >= 0
  ) {
    sortedCustomFields = sortedCustomFields?.sort(
      (a, b) => parseInt(a?.order) - parseInt(b?.order),
    );
  } else {
    sortedCustomFields = sortedCustomFields?.sort(
      (a, b) => parseInt(a?.created_at) - parseInt(b?.created_at),
    );
  }
  return [...additionalFields, ...sortedCustomFields];
};

export const getMappedCustomFields = (
  customFields = [],
  customAdditionalFields,
) => {
  const fieldObj = {};
  const customFieldArr =
    customFields
      ?.map((item) => {
        if (item?.fieldId?.enabled) {
          let value = item.value;
          const customAdditionalFieldItem = customAdditionalFields?.find(
            (f) => f?._id === item?.fieldId?._id,
          );
          if (item?.fieldId?.fieldType === "singleSelect") {
            const matchedOption = item?.fieldId?.options.find(
              (option) => option.value === item.value,
            );
            if (!matchedOption) value = null;
          }
          fieldObj[item?.fieldId?._id] = value;
          const getOptionValue = item?.fieldId?.options?.find(
            (d) => d?.value === item.value,
          );
          return {
            _id: item?.fieldId?._id,
            slug: customAdditionalFieldItem?.slug,
            label: item?.fieldId?.label,
            type: item?.fieldId?.fieldType,
            fieldType: item?.fieldId?.fieldType,
            options: item?.fieldId?.options,
            order: item?.fieldId.order,
            isAdditionalField: item?.fieldId?.isAdditionalField,
            value:
              item?.fieldId?.fieldType === "singleSelect"
                ? getOptionValue?.value
                : item?.value
                ? item?.value
                : "",
            color: getOptionValue?.color || "",
            created_at: customAdditionalFieldItem?.created_at,
          };
        } else return false;
      })
      ?.filter((item) => Boolean(item)) || [];

  return { fieldObj, customFieldArr };
};

const PRIMARY_SECONDARY_COLOR_MAP = mapColorsFromPalette();
export const getOptionBackgroundColor = (color) => {
  return PRIMARY_SECONDARY_COLOR_MAP[color?.toLowerCase() ?? ""];
};
