import PAID_FEATURES from "$/settings/paid-features.json";
import { isOemAdmin } from "~/hooks/_useRoleManager";
import type { SubSettingRouteType } from "~/types/route";
import type { UserType } from "~/types/user";
import { getEnums } from "~/utils";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

export const settings: {
  titleKey: string;
  enableIf: (user?: UserType) => boolean;
  subSettings: SubSettingRouteType[];
}[] = [
  {
    titleKey: "general",
    enableIf: (user: UserType) => isOemAdmin(user.role),
    subSettings: [
      {
        titleKey: "branding",
        enableIf: () => true,
        route: "/app/settings/company/branding",
      },
      {
        titleKey: "companyInfo",
        enableIf: () => true,
        route: "/app/settings/company/general",
      },
      {
        titleKey: "fields",
        enableIf: () => true,
        route: "/app/settings/company/customize-fields",
      },
      {
        titleKey: "dataManagement",
        enableIf: () => true,
        route: "/app/settings/company/data-management",
      },
      {
        titleKey: "userAccounts",
        enableIf: () => true,
        route: "/app/settings/company/users/accounts",
      },
    ],
  },
  {
    titleKey: "workManagement",
    enableIf: (user: UserType) =>
      isOemAdmin(user.role) &&
      user.oem.paidFeatures.includes(paidFeatures.workManagement),
    subSettings: [
      {
        titleKey: "accessPermissions",
        enableIf: () => true,
        route: "/app/settings/company/access-permissions",
      },
      {
        titleKey: "emailConfiguration",
        enableIf: () => true,
        route: "/app/settings/company/email-configuration",
      },
      {
        titleKey: "notifications",
        enableIf: () => true,
        route: "/app/settings/company/notifications",
      },
      {
        titleKey: "resourceManagement",
        enableIf: (user: UserType) =>
          user?.oem.paidFeatures.includes(paidFeatures.scheduler),
        route: "/app/settings/company/resource-management",
      },
      {
        titleKey: "timeTracker",
        enableIf: () => true,
        route: "/app/settings/company/time-tracker",
      },
      {
        titleKey: "statusCustomization",
        enableIf: () => true,
        route: "/app/settings/company/status-customization",
      },
      {
        titleKey: "workOrderCustomization",
        enableIf: () => true,
        route: "/app/settings/company/work-order-customization",
      },
    ],
  },
  {
    titleKey: "personalTitle",
    enableIf: () => true,
    subSettings: [
      {
        titleKey: "calendarSync",
        enableIf: () => true,
        route: "/app/settings/personal/calendar-sync",
      },
      {
        titleKey: "general",
        enableIf: () => true,
        route: "/app/settings/personal/general",
      },
      {
        titleKey: "notifications",
        enableIf: (user: UserType) =>
          user?.oem.paidFeatures.includes(paidFeatures.workManagement),
        route: "/app/settings/personal/notifications",
      },
    ],
  },
];
